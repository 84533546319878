import { useState, useEffect } from 'react'
import {HTTP} from '../../api/http-common';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';


function ReceiptReprint({handleReceiptReprintModal}){
    const [t, i18n] = useTranslation("global");
    const [displayText, setDisplayText] = useState("Please Wait....")
    const [alertShow, setAlertShow] = useState(true);
    const alertHandleClose = () => {
        setAlertShow(false);
        handleReceiptReprintModal()
    }

    const handleReprint = () =>{
        // this is use for manage async value from front end env file
        // let async_trans = process.env.REACT_APP_ASYNC_TRANS
        let request_data = {
            // "async_trans": async_trans,
            workstation_name: localStorage.getItem('workstation')
        }
        // console.log("request data is" + request_data)

        let url = `linkly_payment_gateway/reprint_transaction/`

        // this is use for manage async value from front end env file
        // HTTP.post(url, request_data)
        HTTP.post(url, request_data)
        .then(function(response){
            console.log("reprint response status" + response.status)
            console.log("reprint response " + response.data);
            if(response.status === 200){
                const data = response.data;
                console.log(data)
                setDisplayText(data.response.responseText)
                setAlertShow(true);

            }
            if(response.status === 202){
                const data = response.data;
                const sessionId = response.data.session;
                console.log("data......",  data)
                const socket = new WebSocket(`${process.env.REACT_APP_WS_URL}/linkly_payment_gateway/ws/reprint/${sessionId}`)
                console.log("socket is..............", process.env.REACT_APP_WS_URL)

                socket.addEventListener('open', (event) => {
                    console.log('WebSocket connection opened:', event);
                });


                socket.addEventListener('message', (event) => {
                    console.log("message from server " + event.data)
                    const jsonObject = JSON.parse(event.data)
                    const responseObject = jsonObject.Response
                    if(jsonObject.ResponseType === 'reprintreceipt'){
                        setDisplayText(responseObject.ResponseText)
                        setAlertShow(true);
                    }
                    socket.send(event.data)
                    
                })


                socket.addEventListener('error', (event) => {
                    // Handle WebSocket errors
                    console.log(event)
                    alert('WebSocket Error:');
                });

                
                socket.addEventListener('close', (event) => {
                    console.log('WebSocket connection closed:', event);
                });


                
            }
        })
        .catch(function (err){
            console.log(err)
            // alert(err.response.data.detail)
            setDisplayText(err.response.data.detail)
            setAlertShow(true);
        })
    }

    let count = 0
    useEffect(() => {
        if(count === 0){
            console.log("calling  ")
            handleReprint()
        }
        count = 1
        
    }, [1]);

    return (

        <Modal 
        show={alertShow} 
        onHide={alertHandleClose} 
        backdrop="static"
        keyboard={false}
        animation={false} >
        <Modal.Header closeButton>
                <Modal.Title>{t("Reprint Last Transaction")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center" style={{fontSize:"16px", color:"black"}}>
                    {displayText}
                </div>
            </Modal.Body>
        </Modal>

    );
}
export default ReceiptReprint

ReceiptReprint.propTypes = {
    handleReceiptReprintModal: PropTypes.any,
  };