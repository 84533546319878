import * as React from 'react';
import Pagination from '@mui/material/Pagination';


function CustomPagination(props){
    const [page, setPage] = React.useState(1);
    const totalRows = props.totalRows;
    const totalPage = Math.ceil(totalRows/props.pageSize);


    const handleChange = (event, value) => {

        if (props.calledFrom == 'invoiceList'){
        props.getInvoice(props.fromDateIn, value, props.pageSize)
        console.log("pageSize is......"+ props.pageSize)
        }

        else if(props.calledFrom == 'paymentReview'){
            let inputval = props.inputs
            inputval['page_number']=value
            inputval['page_size']=props.pageSize
            props.submitInputs(inputval)
            props.setInputs(values => ({...values, ['page_number']: value}))
            
        }
        setPage(value);
      };

    return (
        <div>
        <Pagination
            variant="outlined"
            shape="rounded"
            showFirstButton 
            showLastButton
            count={totalPage}
            defaultPage={1} 
            onChange={handleChange}
        />
        </div>
    )
}

export default CustomPagination