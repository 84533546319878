import { HTTP } from '../../api/http-common';
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import MyContext from './MyContext';
import { useTranslation } from "react-i18next";



function FileAttach({ handleFileAttach }) {
  const [t, i18n] = useTranslation("global");
  const [selectedFile, setSelectedFile] = useState(null);
  const [attachVisible, setAttachVisable] = useState(false);
  const [xrefData, setXrefData] = useState([])
  const [fileName, setFileName] = useState(null)
  const [choosenFileName, setChoosenFileName] = useState(null)
  const [embedFile, setEmbedFile] = useState(null)
  const [bgColor, setBgColor] = useState('#fff');
  const [removeBgColor, setRemoveBgColor] = useState('');
  const [showModal, setShowModal] = useState(false)
  const [reloadFlag, setReloadFlag] = useState(false)
  const [attachDisable, setAttachDisable] = useState(false)
  const [alertProcessing, setAlertProcessing] = useState(false)
  const getInvoiceListAPI = useContext(MyContext)
  let dateFormat = process.env.REACT_APP_DATE_FORMAT;

  useEffect(() => {
    setReloadFlag(false)
    setShowModal(true)
    HTTP.get(
      `/file/get_attachment?invoice_number=${localStorage.getItem('invoice_list')}`
    ).then(
      function (response) {
        setXrefData(response.data)
      }

    ).catch(
      function (err) {
        alert("error occured", err)
      }
    )
  }, [reloadFlag]);


  const alertHandleClose = () => {
    setShowModal(false);
    let multi_invoice_list = localStorage.getItem('invoice_list')
    multi_invoice_list = multi_invoice_list.split(",")

    getInvoiceListAPI(multi_invoice_list, false, false)
    handleFileAttach()
  }


  const handleDownload = async () => {
    let filename = fileName
    if(filename === null){
      alert('Please Select the File!')
      return
    }
    console.log('filename,',filename)
    await HTTP.get(`/file/download?file_name=${filename}`, { responseType: 'blob' })
      .then(
        function (response) {
          if (response.status === 200) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.target = '_blank';
            anchor.download = filename;
            anchor.style.display = 'none';

            document.body.appendChild(anchor);
            anchor.click();

            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(url);
          }
        }
      ).catch(
        function (err) {
          const blob = err.response.data;
          const reader = new FileReader();
          reader.onload = function () {
            const errorMessage = JSON.parse(reader.result);
            alert(errorMessage.detail)
          }
          reader.readAsText(blob);

        }
      );
  }


  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setChoosenFileName(e.target.files[0].name)
    if (e.target.files[0]) {
      setEmbedFile(URL.createObjectURL(e.target.files[0]))
      setAttachVisable(true)
    }

    else {
      setAttachVisable(false)
    }
    e.target.value=''
  };


  const handleAttach = async () => {
    setAttachDisable(true)
    setAlertProcessing(true)   
    const formData = new FormData();
    formData.append('file', selectedFile)
    if (localStorage.getItem('invoice_list')) {
      formData.append('invoice_number', localStorage.getItem('invoice_list'))
    }


    await HTTP.post(
      '/file/upload',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

    ).then(
      function (response) {
        alert('Uploaded successfully')
        setChoosenFileName(null)
        setAttachVisable(false)
        setAlertProcessing(false)
        setAttachDisable(false)
        setReloadFlag(true)
      }

    ).catch(
      function (err) {
        setAlertProcessing(false)
        setAttachDisable(false)
        alert(err.response.data.detail)
      }
    )
  }


  const handleXrefData = async (attachmentFilePath, attachmentName, idx) => {
    setEmbedFile(null)
    setBgColor("#fff")
    if (removeBgColor) {
      let rm_id = document.getElementById(`${removeBgColor}`)
      if (rm_id) {
        rm_id.style.backgroundColor = '#fff';
        rm_id.style.color = 'black';
      }
    }
    document.getElementById(`${idx}`).style.backgroundColor = '#076EB0';
    document.getElementById(`${idx}`).style.color = '#fff';
    setRemoveBgColor(idx)
    setFileName(attachmentName)
    let filename = attachmentName
    await HTTP.get(`/file/download?file_name=${filename}`, { responseType: 'blob' })
      .then(
        function (response) {
          if (response.status === 200) {
            console.log("response is" + response.data)
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            setEmbedFile(url)
          }
        }
      ).catch(
        function (err) {
          const blob = err.response.data;
          const reader = new FileReader();
          reader.onload = function () {
            const errorMessage = JSON.parse(reader.result);
            alert(errorMessage.detail)
          }
          reader.readAsText(blob);

        }
      );
    console.log("attachment file path", attachmentFilePath, attachmentName)
  }


  const handleFullView = async () => {
    let filename = fileName
    if(filename === null){
      alert("Please Select the File!")
      return
    }
    await HTTP.get(`/file/download?file_name=${filename}`, { responseType: 'blob' })
      .then(
        function (response) {
          if (response.status === 200) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url)
          }
        }
      ).catch(
        function (err) {
          const blob = err.response.data;
          const reader = new FileReader();
          reader.onload = function () {
            const errorMessage = JSON.parse(reader.result);
            alert(errorMessage.detail)
          }
          reader.readAsText(blob);
        }
      );
  }


  return (
    <>
      <Modal
        show={showModal}
        animation={false}
        centered
        onHide={alertHandleClose}
        size='xl'
        backdrop="static"
        keyboard={false}
        autofocus={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="container">
          <div class="container">
            <div class="row">
            <h4>{t('Invoice')} #:</h4>
              <div class="col-lg me-4 my-2">
              
                <input type="text" className="form-control" disabled value={localStorage.getItem('invoice_list')} id="myInput" name="myInput" />
              
              </div>
              <div class="col-lg-6 d-flex my-2">
              <input class="form-control text-center" id="fileInput" placeholder='Choose File' type="text" value={choosenFileName ? choosenFileName : ''} aria-label="Disabled input example" disabled />
                <input type="file" accept=".pdf" className="custom-file-input" onChange={handleFileChange} id="customFile" style={{ display: 'none' }} />
                <label htmlFor="customFile" className="btn btn-primary">
                Browse
                </label>
              </div>
              <div class="col-lg my-2 d-flex justify-content-center">
              {attachVisible && (
                  <button className="btn btn-primary" disabled={attachDisable} onClick={handleAttach} >Attach</button>
                )}
              </div>
            </div>
          </div>


            <div className="container">
              <div className="row">
                <div className="col-lg-8" >
                  <div className="mt-5 bg-white primary-table table-responsive-sm" style={{ height: '300px', width: "100%", overflowX: "auto", boxShadow: "3px 3px 1px rgb(216, 226, 226)" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t('Invoice')} #</th>
                          <th>{t('Seq')}#</th>
                          <th>{t('Trans Type')}</th>
                          <th>{t('Attachment Display Name')}</th>
                          <th>{t('Trans Media')}</th>
                          <th>{t('Trans Processed By')}</th>
                          <th>{t('Trans Date')}</th>
                          <th>{t('Customernumber')}</th>
                          <th>{t('Journal Entry Id')}</th>
                          <th>{t('Attachment File Path')}</th>
                          <th>{t('Attachment Name')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          xrefData ?
                            xrefData.map((data) => (
                              <tr key={data.PaymentSeqNumber} style={{ backgroundColor: bgColor }} id={data.PaymentSeqNumber} onClick={() => handleXrefData(data.AttachmentFilePath, data.AttachmentName, data.PaymentSeqNumber)}>
                                <td>{data.InvoiceNumber}</td>
                                <td>{data.PaymentSeqNumber}</td>
                                <td>{data.PaymentType}</td>
                                <td>{data.AttachmentDisplayName}</td>
                                <td>{data.PaymentMedia}</td>
                                <td>{data.EnteredByUserId}</td>
                                <td>{moment(new Date(data.EnteredDate)).format(dateFormat.toUpperCase() + ' hh:mm A')}</td>
                                <td>{data.CustomerNumber}</td>
                                <td>{data.JournalEntryId}</td>
                                <td>{data.AttachmentFilePath}</td>
                                <td>{data.AttachmentName}</td>
                              </tr>

                            )) : ""
                        }

                      </tbody>
                    </table>
                  </div>

                  <div className="mt-3 d-flex justify-content-end">
                    <button className="btn btn-primary me-2" onClick={handleFullView}>{t('Full View')}</button>
                    <button className="btn btn-primary" onClick={handleDownload} >{t('Download')}</button>
                  </div>
                </div>

                <div className="mt-5 col-lg-4">
                  <div id="my-container" className="bg-light">
                    {embedFile &&
                      <embed src={embedFile} type="application/pdf" style={{ width: '100%', height: '300px' }}/>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={alertProcessing}
        animation={false} size='sm'
        centered
        backdrop="static"
        keyboard={false}
        autofocus={true}
      >
        <Modal.Body>
          <h3>{t('Processing')} ....</h3>
        </Modal.Body>
      </Modal>


    </>
  );


}

export default FileAttach
