import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";



function CashPaymentsTran(props) {
  const cashPaymentData = props.cashPaymentData
  const [t, i18n] = useTranslation("global");
  return (
    <div>
        <tbody className='mt-5' >
            <tr><td style={{width:"50%", padding:"15px", textAlign:"center"}}>{t('Payment Type')} : {cashPaymentData.PaymentType}</td></tr>
            <tr><td style={{width:"50%", padding:"10px", textAlign:"center"}}>{t('Total Trans Amt')} : {cashPaymentData.TotalTransAmt ? t('$')+parseFloat(cashPaymentData.TotalTransAmt).toFixed(2) : ""} </td></tr>
            <tr><td style={{width:"50%", padding:"10px", textAlign:"center"}}>{t('Amt Applied')} : {cashPaymentData.AmtAppliedToThisInvoice ? t('$')+parseFloat(cashPaymentData.AmtAppliedToThisInvoice).toFixed(2) : ""} </td></tr>
            <tr><td style={{width:"50%", padding:"10px", textAlign:"center"}}>{t('Cash Received')} : {cashPaymentData.CashReceived ? t('$')+parseFloat(cashPaymentData.CashReceived).toFixed(2) : ""} </td></tr>
            <tr><td style={{width:"50%", padding:"10px", textAlign:"center"}}>{t('Change Due')} : {cashPaymentData.ChangeDue ? t('$')+parseFloat(cashPaymentData.ChangeDue).toFixed(2) : ""} </td></tr>
            <tr><td style={{width:"50%", padding:"10px", textAlign:"center"}}>{t('Tax Amt')} : {cashPaymentData.TaxAmt ? t('$')+parseFloat(cashPaymentData.TaxAmt).toFixed(2) : ""} </td></tr>

      </tbody>
    </div>
  )
}

export default CashPaymentsTran


CashPaymentsTran.propTypes = {
  cashPaymentData: PropTypes.any,
};