import React from 'react';

function PageSize(props) {
  const options = [10, 25, 50];

  const handleChange = (event) => {
    let selectedValue = parseInt(event.target.value);
    props.setPageSize(selectedValue)
    console.log("selected value is" + selectedValue)

  };

  return (
    <div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center' }}>
   
      <p style={{ marginLeft: '2px' }}>Total Rows: {props.totalRows}

      <span style={{ marginLeft: '15px' }}>Per Page: </span>

      <select style={{ marginLeft: '5px', height: '25px' }} onChange={handleChange} >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      
      </p>
    </div>
  );
}

export default PageSize;