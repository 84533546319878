import React, {useState, useEffect} from 'react'
import {HTTP} from '../../api/http-common';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";


export default function CreateOtherPayment(props) {
    const [t, i18n] = useTranslation("global");
    const [lookupData, setLookupData] = useState([]);
    const [searchKey, setSearchKey] = useState(2);
    const [searchValue, setSearchValue] = useState('');
    const [hideRowDataRow, setHideRowDataRow] = useState(false);

    const [lookupBgColor, setLookupBgColor] = useState('#fff');
    const [removeLookupBgColorId, setRemoveLookupBgColorId] = useState('');
    const [lookupName, setLookupName] = useState('');
    const [lookupNameTemp, setLookupNameTemp] = useState('');
    const [closeModel, setCloseModel] = useState('');

    
    const handleChangeSerarchKey = (event) => {
        setSearchKey(event.target.value)
        console.log("key " + event.target.value)    
      }

    const handleChangeSerarchValue = (event) => {
        setSearchValue(event.target.value)
        console.log("Value " + event.target.value)
    }

    const handleLookup = () => {
        // alert(searchValue)
        let requestData = {}
        requestData["search_key"] = searchKey
        requestData["search_value"] = searchValue.trim()

        console.log(requestData)

        let url = 'customer-lookup/'
        
        HTTP.post(url, requestData)
        .then(function (response) {
          if(response.status == 200){
                setLookupData(response.data.customer_lookup)

                setTimeout(() => {
                    response.data.customer_lookup?.[0] && 
                    handleRemoveLookupDiv(response.data.customer_lookup[0].CustomerNumber, response.data.customer_lookup[0].CustomerName)
                  }, 500);
                
                  setHideRowDataRow(true)
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    const handleRemoveLookupDiv = (idx, name) => {

        setLookupBgColor("#fff")
        if(removeLookupBgColorId){
           let rm_id = document.getElementById(`${removeLookupBgColorId}`)
            if (rm_id)
            {
             rm_id.style.backgroundColor = '#fff';
             rm_id.style.color = 'black';
            }
        }
        let row_id = document.getElementById(`${idx}`)
        if(row_id){
            row_id.style.backgroundColor = '#076EB0';
            row_id.style.color = '#fff';
        }
        setRemoveLookupBgColorId(idx)
        setLookupNameTemp(name)

        setCloseModel("modal")
      };
    

    const handleSelectCustomer = () => {
        props.setInputs(values => ({...values, ['cust_number']: removeLookupBgColorId}))
        setLookupName(lookupNameTemp)
    }

    const handleAutoFetchCustID = (event) => {
        let search_cust_value = props.inputs.cust_number
        console.log("key blur ", search_cust_value)  
        
        setLookupName("")

        if (search_cust_value && search_cust_value.trim().length >= 3)
            {
                let requestData = {}
                requestData["search_key"] = '1'
                requestData["search_value"] = search_cust_value.trim()
            
                let url = 'customer-lookup/'
                
                HTTP.post(url, requestData)
                .then(function (response) {
                  if(response.status == 200){

                        response.data.customer_lookup?.[0] && props.setInputs(values => ({...values, ['cust_number']: response.data.customer_lookup[0].CustomerNumber}))
                        response.data.customer_lookup?.[0] && setLookupName(response.data.customer_lookup[0].CustomerName)
                  }
                })
                .catch(function (err) {
                  console.log(err);
                });
            }
      }

    let type = ""
    let notes = ""
    let deal = props.dealDisplay
    let changeTypeText = props.changeTypeText
    const getInvoice = props.getInvoice
    let ref_number = ""

    // useEffect(() => {

        if (getInvoice)
        {
            type = getInvoice.invoices[0].InvoiceNumber.substring(3,4)

            notes = getInvoice.invoices[0].Notes
            ref_number = getInvoice.invoices[0].ReferenceNumber

            if(notes && notes.includes("#")){
                if (['V', 'P'].includes(type)) {
                    let split_notes = notes.split('.')
                    notes = split_notes ? split_notes[2] : ""
                    ref_number = split_notes && split_notes[1].split(':') ? split_notes[1].split(':')[1] : ""
                } 
                else if (type === 'C')
                {
                    let split_notes = notes.split(',')
                    notes = split_notes ? split_notes[1] : ""
                    ref_number = split_notes && split_notes[0].split(':') ? split_notes[0].split(':')[1] : ""
                }
                else
                {
                    let split_notes = notes.split('#:')
                    if(notes.includes(","))
                    {
                        let new_split = split_notes[1].split(',')
                        notes = new_split[1]
                        ref_number = new_split[0]
                    }
                    else
                    {
                        ref_number = split_notes ? split_notes[1] : ""
                    }
                }
            }
        }

        if (type == "V" | type == "P")
        {
            deal = ""
            changeTypeText = "Stock #"
        }
        else if (type == "C")
        {
            deal = "none"
            changeTypeText = "Ck #"
        }
        else if (type == "O")
        {
            deal = "none"
            changeTypeText = "Ref #"
        }

    // }, []);


  return (
    <div>
        
        <div className="payment-form mt-2">
            <div className="form-group">
                <label htmlFor="exampleFormControlInput1" className="form-label">{t("POS")} #:</label>
                <input type="text" className="form-control" name="invoice_id" onChange={props.handleChangeInputs}  value={ getInvoice? getInvoice.invoices[0].InvoiceNumber : props.inputs.invoice_id || ""} readOnly/>
            </div>
            <div className="form-group">
                <label htmlFor="exampleRadios1" className="form-label">{t("Type")}:</label>

                <select name="type" id="type" className="form-control" onChange={props.handleSelectType} disabled={getInvoice}>
                    <option value="">{t("Please Select")}</option>
                    <option value="C" selected={type == "C"}>{t("Charge Account")}</option>
                    <option value="V" selected={type == "V"}>{t("Vehicle Deposit")}</option>
                    <option value="P" selected={type == "P"}>{t("Vehicle Final Payment")}</option>
                    <option value="O" selected={type == "O"}>{t("Other Payment")}</option>
                </select>

            </div>

            <div className="form-group" data-bs-toggle="tooltip" data-bs-placement="right" title="Click on 'Cust #' button to get Customer ID">
                <label htmlFor="exampleFormControlInput1" className="form-label" style={{backgroundColor: "#70aafd"}} data-bs-toggle={!getInvoice && "modal"} data-bs-target="#exampleModal" >{t("Cust")} #:</label>
                <input type="text" className="form-control"  
                name="cust_number" onChange={props.handleChangeInputs} onBlur={handleAutoFetchCustID} 
                value={getInvoice ?  getInvoice.invoices[0].CustomerId ? getInvoice.invoices[0].CustomerId : "001" : props.inputs.cust_number || "" } /> 
                
            </div>
            <div className="form-group">
                <label htmlFor="exampleFormControlInput1" className="form-label">{t("Name")}:</label>
                <input type="text" className="form-control" name="name"  onChange={props.handleChangeInputs} value={getInvoice ? getInvoice.invoices[0].CustName : lookupName || ""} readOnly />
            </div>
            <div className="form-group" style={{ display: deal }}>
                <label htmlFor="exampleFormControlInput1" className="form-label">{t("Deal")} #:</label>
                <input type="text" className="form-control" name="deal_number" onChange={props.handleChangeInputs} value={getInvoice ? getInvoice.invoices[0].DealNumber :  props.inputs.deal_number || ""} readOnly={getInvoice}/>
            </div>
            <div className="form-group">
                <label htmlFor="exampleFormControlInput1" className="form-label">{changeTypeText}:</label>
                <input type="text" className="form-control" name="ref_number"  onChange={props.handleChangeInputs} value={getInvoice && ref_number ? ref_number.trim() :  props.inputs.ref_number || "" } readOnly={getInvoice}/>
            </div>
            <div className="form-group">
                <label htmlFor="exampleFormControlInput1" className="form-label">{t("Amount")} {t("$")}:</label>
                <input type="number" className="form-control price-align" name="amount" onChange={props.handleChangeInputs} value={getInvoice ? parseFloat(getInvoice.invoices[0].SubtotalAmt).toFixed(2) :  props.inputs.amount || "" } readOnly={getInvoice}/>
            </div>
            <div className="form-group">
                <label htmlFor="exampleFormControlInput1" className="form-label">{t("Notes")}:</label>
                <textarea name="notes" id="notes" className="form-control" onChange={props.handleChangeInputs} value={getInvoice && notes ? notes.trim() : props.inputs.notes || "" } readOnly={getInvoice}></textarea>
            </div>
            
        </div>


    <div className="modal fade modal-xl" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{t("Customer Lookups")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
                <div className="modal-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-4">

                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">{t("Search Field")}:</label>
                                    <select className="form-select" name="search_key" onChange={handleChangeSerarchKey} aria-label="Default select example">
                                        <option value="2">{t("Name")}</option>
                                        <option value="1">{t("Customer")}#</option>
                                        <option value="3">{t("Address")}</option>
                                        <option value="4">{t("Phone")}</option>
                                        <option value="5">{t("Email")}</option>
                                        {/* <option value="6">*</option> */}
                                    </select>
                                    {/* <label htmlFor="exampleFormControlInput1" className="form-label">({t("Blank for any fields")})</label> */}
                                </div>

                            </div>
                            <div className="col">
                                <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">{t("Search String")}:</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1" value={searchValue} onChange={handleChangeSerarchValue}/>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3" style={{marginTop:"27px"}}>
                                <button disabled={searchValue.length < 3} type="button" className="btn btn-primary " onClick={handleLookup}>
                                    {t("Lookup")}
                                </button> 
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col">
      
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">{t("Customer")} #</th>
                                    <th scope="col">{t("Customer Name")}</th>
                                    <th scope="col">{t("Address")}</th>
                                    <th scope="col">{t("City")}</th>
                                    <th scope="col">{t("State")}</th>
                                    <th scope="col">{t("Zip")}</th>
                                    <th scope="col">{t("Active")}</th>
                                    </tr>
                                </thead>
                                <tbody>

                                {
                                    lookupData && lookupData.length > 0 ?
                                            
                                    lookupData.map(data => (

                                        <tr className='removeLookupColor' style={{backgroundColor: lookupBgColor}} id={data.CustomerNumber} onClick={()=>handleRemoveLookupDiv(data.CustomerNumber, data.CustomerName)}>
                                            <th>{data.CustomerNumber}</th>
                                            <td>{data.CustomerName}</td>
                                            <td>{data.Address1}</td>
                                            <td>{data.City}</td>
                                            <td>{data.State}</td>
                                            <td>{data.Zip}</td>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox"  checked={data.IsActiveFlag | getInvoice.short_amt}/>
                                                </div>
                                            </td>
                                        </tr>

                                    ))  
                                                                        
                                    : ""
                                }

                                </tbody>
                            </table>


                            {
                              (!lookupData || lookupData.length <= 0) && hideRowDataRow && <div className='text-center d-block'> No data found! </div>
                            }

                            </div>
                            <div className="col-2">
                                <button type="button" className="btn btn-primary mt-4" disabled={lookupData.length <= 0} data-bs-dismiss={closeModel} onClick={handleSelectCustomer}>
                                    {t("Select")}
                                </button> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
  )
}


CreateOtherPayment.propTypes = {
    setInputs: PropTypes.any,
    getInvoice: PropTypes.any,
    dealDisplay: PropTypes.any,
    changeTypeText: PropTypes.any,
    handleChangeInputs: PropTypes.any,
    handleSelectType: PropTypes.any,
    inputs: PropTypes.any,

  };
