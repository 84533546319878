import React,{useEffect } from 'react'
import {HTTP} from '../../api/http-common';
import { useNavigate } from 'react-router-dom'

export default function Main() {
  let navigate = useNavigate();

  const user_detail=()=>{
    const username = localStorage.getItem('user_obj');

    let payload={
      "username": username
    }

    HTTP.post("/user_detail", payload)
    .then(function (response) {
      if(response.status === 200){
        localStorage.setItem("user_info",JSON.stringify(response.data))
      }
    })
    .catch(function (err) {
      console.log(err);

    });
  }


  const autologin = () => {
    // const navigate = Navigate();
    let access_token = '';
    let username = '';

    if (typeof window !== "undefined") {
      const queryParameters = new URLSearchParams(window.location.search)
      const access_token = queryParameters.get("token")
      const username = queryParameters.get("username")
      if(access_token !== undefined && access_token != '' && username != undefined && username != ''){
        var fields = {
            access_token: access_token,
            username: username,
        };
        
        HTTP.post("autologin", fields)
            .then(function (response) {
              // console.log(response)
              if(response.status == 200){
                localStorage.setItem("api_token",access_token)
                localStorage.setItem("user_obj",JSON.stringify(response.data.data))
                localStorage.setItem('tag_name', response.data.tag_name)
                navigate("/dashboard");
                //return <Navigate replace to="/dashboard" />;
              }
              // window.location.href = "/dashboard";
              // console.log(access_token);
            })
            .catch(function (err) {
              console.log(err);
              let REACT_APP_SSO_ROOT_URL = process.env.REACT_APP_SSO_ROOT_URL
              let REACT_APP_FRONT_ROOT_URL = process.env.REACT_APP_FRONT_ROOT_URL
              let REACT_APP_PROJECT_SLUG = process.env.REACT_APP_PROJECT_SLUG
              console.log('autologin');
              let redirect_url = REACT_APP_SSO_ROOT_URL + "/login?redirect=";
              console.log(redirect_url);
            });
        }
    }
  }
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
        if (typeof window !== "undefined") {
            const queryParameters = new URLSearchParams(window.location.search)
      const access_token = queryParameters.get("token")
      const username = queryParameters.get("username")
      if(access_token !== undefined && access_token != '' && username != undefined && username != ''){
        localStorage.setItem("api_token",access_token)
        localStorage.setItem("user_obj",username)
        autologin();
        user_detail();
      }else{
        let access_token = localStorage.getItem("api_token")
        
        if(access_token == null){
          let REACT_APP_SSO_ROOT_URL = process.env.REACT_APP_SSO_ROOT_URL
          let REACT_APP_FRONT_ROOT_URL = process.env.REACT_APP_FRONT_ROOT_URL
          let REACT_APP_PROJECT_SLUG = process.env.REACT_APP_PROJECT_SLUG
          // alert(REACT_APP_SSO_ROOT_URL + "/login?redirect=" + REACT_APP_FRONT_ROOT_URL + "&slug=" + REACT_APP_PROJECT_SLUG)
          window.location.href = REACT_APP_SSO_ROOT_URL + "/login?redirect=" + REACT_APP_FRONT_ROOT_URL + "&slug=" + REACT_APP_PROJECT_SLUG;
          const redirect_url = REACT_APP_SSO_ROOT_URL + "/login?redirect=" + REACT_APP_FRONT_ROOT_URL + "&slug=" + REACT_APP_PROJECT_SLUG;
          console.log(redirect_url);
        }else{
          navigate("/dashboard");
        }
      }
    }
  },[]);
  return (
    <div>
      {/* <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small> */}
    </div>
  )
}
