import axios from 'axios';

export const HTTP = axios.create({

    // baseURL: process.env.ROOT_API,
    baseURL: process.env.REACT_APP_COMMON_API_BASE_URL,

    headers: {
      'accept': 'application/json',
      'Content-Type': 'application/json',
    },
});


HTTP.interceptors.request.use(
  function(config) {
      const token = localStorage.getItem('api_token');
      if (token) {
          config.headers.Authorization = `Bearer ${token}`;
      }

      // Add default payload to the request data
      const userInfo = JSON.parse(localStorage.getItem('user_info'));
      if (userInfo) {
          config.headers['User-Info'] = localStorage.getItem('user_info');
      }
      
      return config;
  },
  function(error) {
      return Promise.reject(error);
  }
);