import { useEffect } from 'react';

const AutoLogout = () => {

  useEffect(() => {
    let inactivityTimer;
    const resetInactivityTimer = (timeout) => {
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(handleLogout, timeout);
    };

    const handleActivity = () => { 
        const timeout = parseInt(localStorage.getItem('inactive_timeout'), 10) || 2 * 60 * 60 * 1000; // Default to 2 hours if not set
        resetInactivityTimer(timeout);
    };

    const handleLogout = () => {
        localStorage.clear()
        let REACT_APP_SSO_ROOT_URL = process.env.REACT_APP_SSO_ROOT_URL
        let REACT_APP_FRONT_ROOT_URL = process.env.REACT_APP_FRONT_ROOT_URL
        let REACT_APP_PROJECT_SLUG = process.env.REACT_APP_PROJECT_SLUG
        window.location.href = REACT_APP_SSO_ROOT_URL + "/logout?redirect_url=" + REACT_APP_FRONT_ROOT_URL + "&slug=" + REACT_APP_PROJECT_SLUG;
    };

    // Set up event listeners for user activity
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keypress', handleActivity);
    document.addEventListener('mousedown', handleActivity);
    document.addEventListener('touchstart', handleActivity);

    // Start the initial inactivity timer
    const timeout = parseInt(localStorage.getItem('inactive_timeout'), 10) || 2 * 60 * 60 * 1000; // Default to 2 hours if not set
    resetInactivityTimer(timeout);

    // Clean up event listeners on unmount
    return () => {
        document.removeEventListener('mousemove', handleActivity);
        document.removeEventListener('keypress', handleActivity);
        document.addEventListener('mousedown', handleActivity);
        document.addEventListener('touchstart', handleActivity);
        clearTimeout(inactivityTimer);
    };
  }, []);
};

export default AutoLogout;
