import logo from './logo.svg';
import './App.css';
// require('dotenv').config()
import { Routes, Route } from 'react-router-dom'
import useDocumentTitle from './useDocumentTitle'
import Home from './components/pages/Home';
import Main from './components/pages/Main';
import Dashboard from './components/pages/Dashboard';
import PaymentReview from './components/pages/PaymentReview';
import ReceiptReprint from './components/pages/ReceiptReprint';
import LinklyPairing from './components/pages/LinklyPairing';
import InvoiceListReports from './components/pages/InvoiceList&Reports';
import FileAttach from './components/pages/FileAttach';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './components/partials/Layout';

function App() {
  const navigate = useNavigate();
  const token = localStorage.getItem('api_token')
    useEffect(() => {
        if (!token) {
            navigate("/");
        }
    }, [token]);
  return (
    <Layout>
      <Routes>
        <Route exact path='/' element={<Main />} />
        <Route exact path='/dashboard' element={<Dashboard />} />
        <Route exact path='payment/review' element={<PaymentReview />} />
        <Route exact path='invoice/reports' element={<InvoiceListReports />} />
        <Route exact path='/reprint/last/transaction' element={<ReceiptReprint />} />
        <Route exact path='pairing/linkly' element={<LinklyPairing />} />
        <Route exact path='/file/attach' element={<FileAttach />} />
      </Routes>
      </Layout>
  );
}

export default App;
