import React, {useEffect, useState} from 'react'
import {HTTP} from '../../api/http-common';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";



export default function PaymentReviewInputs(props) {
    const [t, i18n] = useTranslation("global");
    const [lookupData, setLookupData] = useState([]);
    const [searchKey, setSearchKey] = useState(2);
    const [searchValue, setSearchValue] = useState('');
    const [hideRowDataRow, setHideRowDataRow] = useState(false);

    const [lookupBgColor, setLookupBgColor] = useState('#fff');
    const [removeLookupBgColorId, setRemoveLookupBgColorId] = useState('');
    const [lookupNameTemp, setLookupNameTemp] = useState('');
    const [closeModel, setCloseModel] = useState('');

    
    const handleChangeSerarchKey = (event) => {
        setSearchKey(event.target.value)
        console.log("key " + event.target.value)    
      }

    const handleChangeSerarchValue = (event) => {
        setSearchValue(event.target.value)
        console.log("Value " + event.target.value)
    }

    const handleLookup = () => {
        let requestData = {}
        requestData["search_key"] = searchKey
        requestData["search_value"] = searchValue.trim()

        console.log(requestData)

        let url = 'customer-lookup/'
        
        HTTP.post(url, requestData)
        .then(function (response) {
          if(response.status == 200){
                setLookupData(response.data.customer_lookup)

                setTimeout(() => {
                    response.data.customer_lookup?.[0] && 
                    handleRemoveLookupDiv(response.data.customer_lookup[0].CustomerNumber, response.data.customer_lookup[0].CustomerName)
                  }, 500);
                
                setHideRowDataRow(true)
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    const handleAutoFetchCustID = (event) => {
        let search_cust_value = props.inputs.customer_id
        console.log("key blur ", search_cust_value)  
        
        props.setLookupName("")

        if (search_cust_value && search_cust_value.trim().length >= 3)
            {
                let requestData = {}
                requestData["search_key"] = '1'
                requestData["search_value"] = search_cust_value.trim()
            
                let url = 'customer-lookup/'
                
                HTTP.post(url, requestData)
                .then(function (response) {
                  if(response.status == 200){
                        response.data.customer_lookup?.[0] && props.setInputs(values => ({...values, ['customer_id']: response.data.customer_lookup[0].CustomerNumber}))
                        response.data.customer_lookup?.[0] && props.setLookupName(response.data.customer_lookup[0].CustomerName)
                  }
                })
                .catch(function (err) {
                  console.log(err);
                });
            }
      }

    const handleRemoveLookupDiv = (idx, name) => {

        setLookupBgColor("#fff")
        if(removeLookupBgColorId){
           let rm_id = document.getElementById(`${removeLookupBgColorId}`)
            if (rm_id)
            {
             rm_id.style.backgroundColor = '#fff';
             rm_id.style.color = 'black';
            }
        }

        let row_id = document.getElementById(`${idx}`)
        if(row_id){
            row_id.style.backgroundColor = '#076EB0';
            row_id.style.color = '#fff';
        }
        setRemoveLookupBgColorId(idx)
        setLookupNameTemp(name)

        setCloseModel("modal")
      };
    

    const handleSelectCustomer = () => {
        props.setInputs(values => ({...values, ['customer_id']: removeLookupBgColorId}))
        props.setLookupName(lookupNameTemp)
    }

    let currentDate = new Date();
    let toDate = currentDate.toISOString().split('T')[0];

    let fromDate = new Date(currentDate);
    fromDate.setDate(currentDate.getDate() - 180);
    fromDate = fromDate.toISOString().split('T')[0];

    const defaultStartDate = new Date(fromDate);
    const defaultEndDate = new Date(toDate);

    useEffect(() => {
        if(!(props.inputs.from_date))
        {
            props.setInputs(values => ({...values, ['from_date']: fromDate}))
        }
        if(!(props.inputs.to_date))
        {
            props.setInputs(values => ({...values, ['to_date']: toDate}))
        }
      }, []);
      
    let dateFormat = process.env.REACT_APP_DATE_FORMAT;
    if(!(dateFormat)){
        dateFormat = 'MM-dd-yyyy'
    }

    const handleDateRange = (range) => {
        console.log(range)
        if(range){
            let dateObject = new Date(range[0]);
            let year = dateObject.getFullYear();
            let month = dateObject.getMonth() + 1; // Months are zero-indexed, so add 1
            let day = dateObject.getDate();
            // Format the date
            let fromDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            console.log(fromDate + " from date");

            dateObject = new Date(range[1]);
            year = dateObject.getFullYear();
            month = dateObject.getMonth() + 1; // Months are zero-indexed, so add 1
            day = dateObject.getDate();
            // Format the date
            let toDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

            console.log(toDate);

            props.setInputs(values => ({...values, ['from_date']: fromDate}))
            props.setInputs(values => ({...values, ['to_date']: toDate}))
        }

        
    }


  return (
    <div>
        
        
        <div className="payment-form mt-1">

            <DateRangePicker 
                onChange={handleDateRange}
                block
                format = {dateFormat}
                character = ' To '
                defaultValue = {[defaultStartDate, defaultEndDate]}
                
            />

            <div className="form-group mt-2" data-bs-toggle="tooltip" data-bs-placement="right" title="Click on 'Cust #' button to get Customer ID">
                <label htmlFor="label-input" className="form-label" style={{backgroundColor: "#70aafd"}} data-bs-toggle="modal" data-bs-target="#exampleModal">{t("Cust")} #:</label>
                <input type="text" className="form-control" name="customer_id" onChange={props.handleChangeInputs} onBlur={handleAutoFetchCustID}   value={ props.inputs.customer_id} /> 
            </div>
            <div className="form-group">
                <label htmlFor="label-input" className="form-label">{t("Name")}:</label>
                <input type="text" className="form-control" name="customer_name"  onChange={props.handleChangeInputs} value={props.lookupName} readOnly/>
            </div>
            <div className="form-group">
                <label htmlFor="label-input" className="form-label">{t("Invoice")} #:</label>
                <input type="text" className="form-control" name="invoice"  onChange={props.handleChangeInputs} value={props.inputs.invoice }/>
            </div>
            <div className="form-group">
                <label htmlFor="label-input" className="form-label">{t("Card")} # (Last 3):</label>
                <input type="text" className="form-control" maxLength={3} name="card_no" onChange={props.handleChangeInputs} value={props.inputs.card_no }/>
            </div>
            <div className="form-group">
                <label htmlFor="label-input" className="form-label">{t("Payment Amt")} {t("$")}:</label>
                <input type="number" className="form-control price-align" name="payment_amount"  onChange={props.handleChangeInputs} value={props.inputs.payment_amount }/>
            </div>
            
        </div>


        <div className="modal fade modal-xl" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{t("Customer Lookup")}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-4">

                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">{t("Search Field")}:</label>
                                        <select className="form-select" name="search_key" onChange={handleChangeSerarchKey} aria-label="Default select example">
                                            <option value="2">{t("Name")}</option>
                                            <option value="1">{t("Customer")}#</option>
                                            <option value="3">{t("Address")}</option>
                                            <option value="4">{t("Phone")}</option>
                                            <option value="5">{t("Email")}</option>
                                            {/* <option value="6">*</option> */}
                                        </select>
                                        {/* <label htmlFor="exampleFormControlInput1" className="form-label">({t("Blank for any fields")})</label> */}
                                    </div>

                                </div>
                                <div className="col">
                                    <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">{t("Search String")}:</label>
                                    <input type="text" className="form-control" id="exampleFormControlInput1" value={searchValue} onChange={handleChangeSerarchValue}/>
                                    {/* <label htmlFor="exampleFormControlInput1" className="form-label">({t("Blank for all customers")})</label> */}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3" style={{marginTop:"27px"}}>
                                    <button type="button" disabled={searchValue.length < 3} className="btn btn-primary" onClick={handleLookup}>
                                        {t("Lookup")}
                                    </button> 
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col">
        
                                <table className="table">
                                    <thead>
                                        <tr>
                                        <th scope="col">{t("Customer")} #</th>
                                        <th scope="col">{t("Customer Name")}</th>
                                        <th scope="col">{t("Address")}</th>
                                        <th scope="col">{t("City")}</th>
                                        <th scope="col">{t("State")}</th>
                                        <th scope="col">{t("Zip")}</th>
                                        <th scope="col">{t("Active")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    {
                                        lookupData && lookupData.length > 0 ?
                                                
                                        lookupData.map(data => (

                                            <tr className='removeLookupColor' style={{backgroundColor: lookupBgColor}} id={data.CustomerNumber} onClick={()=>handleRemoveLookupDiv(data.CustomerNumber, data.CustomerName)}>
                                                <th>{data.CustomerNumber}</th>
                                                <td>{data.CustomerName}</td>
                                                <td>{data.Address1}</td>
                                                <td>{data.City}</td>
                                                <td>{data.State}</td>
                                                <td>{data.Zip}</td>
                                                <td>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox"  checked={data.IsActiveFlag}/>
                                                    </div>
                                                </td>
                                            </tr>

                                        ))  
                                                                            
                                        : ""
                                    }

                                    </tbody>
                                </table>

                                {
                                    (!lookupData || lookupData.length <= 0) && hideRowDataRow && <div className='text-center d-block'> No data found! </div>
                                }

                                </div>
                                <div className="col-2">
                                    <button type="button" className="btn btn-primary mt-4" disabled={lookupData.length <= 0} data-bs-dismiss={closeModel} onClick={handleSelectCustomer}>
                                        {t("Select")}
                                    </button> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}


PaymentReviewInputs.propTypes = {
    setInputs: PropTypes.any,
    setLookupName: PropTypes.any,
    inputs: PropTypes.any,
    handleChangeInputs: PropTypes.any,
    lookupName: PropTypes.any,
  };
