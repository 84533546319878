import { useState, useEffect } from 'react'
import {HTTP} from '../../api/http-common';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import moment from 'moment';


function CheckLastTransaction({handleCheckLastTrans}){
    let dateFormat = process.env.REACT_APP_DATE_FORMAT;
    const [t, i18n] = useTranslation("global");
    const [displayText, setDisplayText] = useState()
    const [alertShow, setAlertShow] = useState(false);
    const [resData, setResData] = useState();
    const [alertProcessing, setAlertProcessing] = useState(true);

    const alertHandleClose = () => {
        setAlertShow(false);
        handleCheckLastTrans()
    }

    const maskCardNumber = (accNumber) =>{
        accNumber = accNumber.trim();

        let maskedNumber = accNumber.slice(0, 4) + '.'.repeat(accNumber.length - 7) + accNumber.slice(-3);
    
        return maskedNumber;
    }

    const paymentAmount = (data) =>{
        const amt = data.transaction.response.amtPurchase;
        const txn_type = data.transaction.response.txnType;
        if(txn_type === "R")
        {
            return {"amount": -amt/100, "action": "REFUND"};
        }
        else{
            return {"amount": amt/100, "action": "SALE"}
        }
    }

    const handleIncompleteTransaction = (data) =>{
        let multi_invoice_list = data.invoice_number
        console.log("multi invoic eis", multi_invoice_list)
        multi_invoice_list = multi_invoice_list.split(",")
        let invoice_list = {"invoices": multi_invoice_list}
        HTTP.post('invoice/get-multiple-invoices', invoice_list)
                    .then(function (response) {
                    if(response.status === 200){
                        let invoice_list_data = response.data
                        console.log("res..........", data)
                        if(data.transaction.response.cardType.trim() === 'DEBIT CARD')
                        {
                            var paymentMedia = 'DEBIT'
                        }
                        else{
                            var paymentMedia = 'CREDIT'
                        }
                        const amtAction = paymentAmount(data)
                        console.log("payment media is............."+ paymentMedia)
                        let request_data = {
                            "action": amtAction.action,
                            "payment_amt": amtAction.amount,
                            "estimate_not_invoice": "yes",
                            "payment_trans_id": parseInt(data.transaction.response.txnRef),
                            "payment_method": paymentMedia,
                            "payment_media": data.transaction.response.cardType,
                            "account_number": data.transaction.response.pan,
                            "trans_type": paymentMedia,
                            "term_id": data.transaction.response.catid,
                            "async_trans": "true",
                            "start_process": false,
                            "invoice_list": [invoice_list_data]
                        }

                        let url = '/debit-card-payment'
                        HTTP.post(url, request_data)
                            .then(function (response) {
                                console.log("Card Payment " + response.data)
                
                            })
                            .catch(function (err) {
                                console.log(err);
                            });


                    } 
                    
                }).catch(function (err) {
                    console.log(err);
                    });
    }

    const checkLastTrans = () =>{
        setDisplayText("")
        setResData()

        // this is use for manage async value from front end env file
        // let request_data = {
            // "async_trans": "true"
        // }
        let workstation_name = localStorage.getItem('workstation')
        
        let url = `linkly_payment_gateway/check_last_transaction?workstation_name=${workstation_name}`

        // this is use for manage async value from front end env file
        // HTTP.get(url, request_data)
        HTTP.get(url)
        .then(function(response){
            
            if(response.status === 200){

                const data = response.data;
                const transaction_complete = data.transaction_complete

                setResData(data)
                
                setAlertShow(true);
                if (!transaction_complete){
                    handleIncompleteTransaction(data);
                
                }
                setAlertProcessing(false)
            }
            if(response.status === 202){
                const data = response.data;
                const sessionId = response.data.session;
                console.log("data......",  data)
                const socket = new WebSocket(`${process.env.REACT_APP_WS_URL}/linkly_payment_gateway/ws/reprint/${sessionId}`)
                console.log("socket is......", process.env.REACT_APP_WS_URL)

                socket.addEventListener('open', (event) => {
                    console.log('WebSocket connection opened:', event);
                });


                socket.addEventListener('message', (event) => {
                    console.log("message from server " + event.data)
                    const jsonObject = JSON.parse(event.data)
                    const responseObject = jsonObject.Response
                    if(jsonObject.ResponseType === 'reprintreceipt'){
                        setDisplayText(responseObject.ResponseText)
                        setAlertShow(true);
                    }
                    socket.send(event.data)
                    
                })


                socket.addEventListener('error', (event) => {
                    // Handle WebSocket errors
                    console.log(event)
                    alert('WebSocket Error:');
                });

                
                socket.addEventListener('close', (event) => {
                    console.log('WebSocket connection closed:', event);
                });


                
            }
        })
        .catch(function (err){
            console.log(err)
            setDisplayText(err.response.data.detail)
            setAlertShow(true);
        })
    }

    let count = 0
    useEffect(() => {
        if(count === 0){
            console.log("calling  ")
            checkLastTrans()
        }
        count = 1
        
    }, [1]);

    return (
        <>
        <Modal 
        show={alertShow} 
        onHide={alertHandleClose} 
        backdrop="static"
        centered
        keyboard={false}
        animation={false} >
        <Modal.Header closeButton>
                <Modal.Title>{t("Check Last Transaction")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {
                    displayText ?
                        <div className="text-center" style={{fontSize:"16px", color:"black"}}>
                            {displayText}
                        </div>
                    :""
                }

                {
                    resData?
                        
                        <div className="align-items-center text-center">
                            <div className="m-2"><span style={{fontSize:"16px", color:"black"}}>{t("Invoice")}: </span> {resData.invoice_number}</div>
                            <div className="m-2"><span style={{fontSize:"16px", color:"black"}}>{t("Payment Media")}: </span> {resData.transaction.response.cardType}</div>
                            <div className="m-2"><span style={{fontSize:"16px", color:"black"}}>{t("Acct")}#: </span> {resData.transaction.response.pan.trim()?maskCardNumber(resData.transaction.response.pan.trim()):resData.transaction.response.pan}</div>
                            <div className="m-2"><span style={{fontSize:"16px", color:"black"}}>{t("Amount")}: </span> {(resData.transaction.response.amtPurchase)/100}</div>
                            <div className="m-2"><span style={{fontSize:"16px", color:"black"}}>{t("Trans Date")}: </span> {moment(new Date(resData.transaction.response.date)).format(dateFormat.toUpperCase() + ' hh:mm A')}</div>
                            <div className="m-2"><span style={{fontSize:"16px", color:"black"}}>{t("Result")}: </span> {resData.transaction.response.success?"True":"False"}</div>
                        
                        </div>

                                      
                    : ""
                }
                    

            </Modal.Body>
        </Modal>

        <Modal 
        show={alertProcessing}
        animation={false} size='sm' 
        centered
        backdrop="static"
        keyboard={false}
        autofocus={true}
        >
        <Modal.Body>
            <h3> Processing ....</h3>
        </Modal.Body>
        </Modal>
        </>

    );


}
export default CheckLastTransaction


CheckLastTransaction.propTypes = {
    handleCheckLastTrans: PropTypes.any,

  };