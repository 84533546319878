import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";


function CardPaymentsTran(props) {
  const cardPaymentData = props.cardPaymentData
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      {/* Transaction */}
        <tbody>
            <tr><td className="text-left">{t("Response Text")} : {cardPaymentData.RESPONSE_TEXT}</td></tr>
            <tr><td className="text-left">{t("Result")} : {cardPaymentData.RESULT}</td></tr>
            <tr><td className="text-left">{t("Result Code")} : {cardPaymentData.RESULT_CODE}</td></tr>
            <tr><td className="text-left">{t("Status")} : {cardPaymentData.TERMINATION_STATUS}</td></tr>
            <tr><td className="text-left">{t("Merchant ID")} : {cardPaymentData.MERCHID}</td></tr>
            <tr><td className="text-left">{t("Terminal ID")} : {cardPaymentData.TERMID}</td></tr>
            <tr><td className="text-left">{t("Auth Code")} : {cardPaymentData.AUTH_CODE}</td></tr>
            <tr><td className="text-left">{t("Payment Type")} : {cardPaymentData.PAYMENT_TYPE}</td></tr>
            <tr><td className="text-left">{t("Trans Date")} : {cardPaymentData.TRANS_DATE}</td></tr>
            <tr><td className="text-left">{t("Trans Time")} : {cardPaymentData.TRANS_TIME}</td></tr>
            <tr><td className="text-left">{t("Approved Amount")} :{cardPaymentData.APPROVED_AMOUNT ? '$' + parseFloat(cardPaymentData.APPROVED_AMOUNT).toFixed(2) : ""} </td></tr>
            <tr><td className="text-left">{t("Trans Amount")} :{cardPaymentData.TRANS_AMOUNT ? '$' + parseFloat(cardPaymentData.TRANS_AMOUNT).toFixed(2) : ""} </td></tr>
            <tr><td className="text-left">{t("Payment Media")} : {cardPaymentData.PAYMENT_MEDIA}</td></tr>
            <tr><td className="text-left">{t("Acct Num")} : {cardPaymentData.ACCT_NUM}</td></tr>

            <tr><td className="text-left">{t("Cardholder")} : {cardPaymentData.CARDHOLDER}</td></tr>
            <tr><td className="text-left">{t("EMV CVM")} : {cardPaymentData.EMV_CVM}</td></tr>
            <tr><td className="text-left">{t("Trans Seq Num")} : {cardPaymentData.TRANS_SEQ_NUM}</td></tr>
            <tr><td className="text-left">{t("Intrn Seq Num")} : {cardPaymentData.INTRN_SEQ_NUM}</td></tr>
            <tr><td className="text-left">{t("TROUTD")} : {cardPaymentData.TROUTD}</td></tr>
            <tr><td className="text-left">{t("CTROUTD")} : {cardPaymentData.CTROUTD}</td></tr>
            <tr><td className="text-left">{t("Lp Token")} : {cardPaymentData.LPTOKEN}</td></tr>
            <tr><td className="text-left">{t("Reference")} : {cardPaymentData.REFERENCE}</td></tr>
            <tr><td className="text-left">{t("Card Exp Month")} : {cardPaymentData.CARD_EXP_MONTH}</td></tr>
            <tr><td className="text-left">{t("Card Exp Year")} : {cardPaymentData.CARD_EXP_YEAR}</td></tr>
            <tr><td className="text-left">{t("Card Entry Mode")} : {cardPaymentData.CARD_ENTRY_MODE}</td></tr>
            <tr><td className="text-left">{t("Card Abbrv")} : {cardPaymentData.CARD_ABBRV}</td></tr>
            <tr><td className="text-left">{t("Clientid")} : {cardPaymentData.ClientID}</td></tr>
            <tr><td className="text-left">{t("Cashierid")} : {cardPaymentData.CashierID}</td></tr>
      </tbody>
    </div>
  )
}

export default CardPaymentsTran


CardPaymentsTran.propTypes = {
  cardPaymentData: PropTypes.any,

};