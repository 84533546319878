import React, { Component } from 'react'
import { Outlet, Link } from "react-router-dom";
import LinklyPairing from '../pages/LinklyPairing';
import ReceiptReprint from '../pages/ReceiptReprint';
import CheckLastTransaction from '../pages/CheckLastTransaction';
import { withTranslation } from "react-i18next"
import FileAttach from '../pages/FileAttach';
import { HTTP } from '../../api/http-common';

class Header extends Component {
    constructor(){
        super()
        let userObj = localStorage.getItem("user_obj")
        let iconLetters = '';
        let firstName = "Mohd";
        let lastName = "Nafees";
        let username = "";
        let showPairModel = false;
        let reciptReprint = false;
        let checkLastTransModal = false;
        let fileAttachModal = false;
        this.handleShowPairModel = this.handleShowPairModel.bind(this);
        this.handleReceiptReprintModal = this.handleReceiptReprintModal.bind(this);
        this.handleCheckLastTrans = this.handleCheckLastTrans.bind(this);
        this.handleFileAttach = this.handleFileAttach.bind(this);
        if (userObj) {
            let userJson = JSON.parse(userObj);
            // console.log(userJson)
            username = userJson["username"];
            firstName = userJson["first_name"];
            lastName = userJson["last_name"];
            iconLetters = username.substring(0,2).toUpperCase();
        }
        this.state = {
            iconLetters : iconLetters,
            firstName : firstName,
            lastName : lastName,
            username : username,
            showPairModel : showPairModel,
            reciptReprint : reciptReprint,
            checkLastTransModal : checkLastTransModal,
            fileAttachModal: fileAttachModal,
            workstations:[],
        }
    }

    handleShowPairModel() {
        console.log(this.state.showPairModel + " headers")
        this.setState((prevState) => ({
            showPairModel: !prevState.showPairModel,
          }));
    }

    handleReceiptReprintModal(){
        const workstation = localStorage.getItem('workstation');
        if (!workstation) {
            alert('Please select the Workstation first!');
            return
          }
        console.log(this.state.reciptReprint + " headers")
        this.setState((prevState) => ({
            reciptReprint: !prevState.reciptReprint,
          }));
    }

    handleCheckLastTrans(){
        console.log(this.state.checkLastTransModal + " headers")
        const workstation = localStorage.getItem('workstation');
        if (!workstation) {
            alert('Please select the Workstation first!');
            return
          }
        this.setState((prevState) => ({
            checkLastTransModal: !prevState.checkLastTransModal,
          }));

    }

    handleFileAttach(){
        let invoice_list = localStorage.getItem('invoice_list')

        if(!invoice_list){
            alert("Please retrieve at least one Invoice to scan to check Stub associated with!")
            return false;
        }

        else{

            let  invoicesArray = invoice_list.split(',');
            
            if(invoicesArray.length>1){
                alert("Please process one Invoice at a time!")
                return false;
            }
        }
        
        
        this.setState((prevState) => ({
            fileAttachModal: !prevState.fileAttachModal,
          }));

    }

    handleChangeLanguage = (lang) => {
        const { i18n } = this.props;
        localStorage.setItem('language', lang)
        i18n.changeLanguage(lang);
    }

    handleLogout=()=>{
        localStorage.clear()
        let REACT_APP_SSO_ROOT_URL = process.env.REACT_APP_SSO_ROOT_URL
        let REACT_APP_FRONT_ROOT_URL = process.env.REACT_APP_FRONT_ROOT_URL
        let REACT_APP_PROJECT_SLUG = process.env.REACT_APP_PROJECT_SLUG
        window.location.href = REACT_APP_SSO_ROOT_URL + "/logout?redirect_url=" + REACT_APP_FRONT_ROOT_URL + "&slug=" + REACT_APP_PROJECT_SLUG;
    }

    handleWorkstationClick(workstation) {
        localStorage.setItem('workstation', workstation);
    }


    // componentDidMount is called after the component is mounted
    componentDidMount() {
        // Code to be executed on page load
        let wait_timer = 0
        if(!(localStorage.getItem('user_info')))
        {
            wait_timer = 5000
        }

        setTimeout(() => {
            HTTP.get('/linkly_payment_gateway/workstation_name')
            .then(response => {
    
                this.setState({ workstations: response.data.workstation_name });
                if(response.data.workstation_name[0] && !(localStorage.getItem('workstation')))
                {   
                    localStorage.setItem('workstation', response.data.workstation_name[0]);
                    localStorage.setItem('inactive_timeout', response.data.inactive_timeout)
                }
                else if(response.data.tag_name !== localStorage.getItem('tag_name'))
                    {
                        localStorage.setItem('tag_name', response.data.tag_name);
                        localStorage.setItem('inactive_timeout', response.data.inactive_timeout)
                    }
            })
            .catch(error => {
                console.error('Error fetching workstation names:', error);
            });
        }, wait_timer);
            
    }


    truncateText = (text, maxLength) =>{
        if (text && text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        } else {
            return text;
        }
    }

  render() {
    const { t } = this.props;
    const { workstations } = this.state;
    return (
        <>
            <header>
                <div className="logo">
                    <a href="/"><img src={process.env.PUBLIC_URL + process.env.REACT_APP_LOGO_IMAGE_PATH} alt='logo'/></a>

                    {/* <i className="vvg-hamburger-menu hamburger-menu"></i> */}
                </div>
                <div className="row menu-containers">

                    <div className="header-icons col-md">

                        <div className="user-profile dropdown">
                            <Link to="#" data-bs-toggle="dropdown" role="" className="text-decoration-none" aria-expanded="false">
                                <div className="wrapper">
                                    <span>{ localStorage.getItem('workstation') ? localStorage.getItem('workstation') : "Workstation name" } <i className="vvg-arrow-down"></i></span>
                                </div>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-end">
                            {workstations.length > 0 ? (
                                workstations.map((workstation, index) => (
                                <Link key={index} to="#" className="dropdown-item" onClick={() => this.handleWorkstationClick(workstation)}>
                                    {workstation}
                                </Link>
                                ))
                                ) : (
                                <span>{t("global:No_Workstations_Available")}</span>
                            )}
                            </div>
                        </div>

                        <div className="user-profile dropdown">
                            <Link to="#" data-bs-toggle="dropdown" role="" className="text-decoration-none" aria-expanded="false">
                                <div className="wrapper wrapper2">
                                    <span>{t("global:Language")}<i className="vvg-arrow-down"></i> </span>
                                </div>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-end">
                                <Link to="#" onClick={() => this.handleChangeLanguage('en')} className="dropdown-item"><i className=""></i> {t('global:English')}</Link>
                                <Link to="#" onClick={() => this.handleChangeLanguage('es')} className="dropdown-item"><i className=""></i> {t('global:Spanish')}</Link>
                            </div>
                        </div>

                        <div className="user-profile dropdown">
                            <Link to="#" data-bs-toggle="dropdown" role="" className="text-decoration-none" aria-expanded="false">
                                <div className="wrapper wrapper2">
                                    <span>{t("global:User")}<i className="vvg-arrow-down"></i> </span>
                                </div>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-end">
                                <Link to="/dashboard" className="dropdown-item"><i className=""></i>{t('global:Dashboard')}</Link>
                                <Link to="/invoice/reports" className="dropdown-item"><i className=""></i>{t("global:Invoice_List_&_Reports")}</Link>
                                <Link to="#" onClick={this.handleCheckLastTrans} className="dropdown-item"><i className=""></i>{t("global:Check_Last_Transaction")}</Link>
                                <Link to="#" onClick={this.handleReceiptReprintModal} className="dropdown-item"><i className=""></i>{t("global:Reprint_Last_Transaction")}</Link>
                                {/* <Link to="/file/attach"  className="dropdown-item"><i className=""></i>Attach PDF Files</Link> */}
                                <Link to="#" onClick={this.handleFileAttach}  className="dropdown-item"><i className=""></i>{t("global:Attach_PDF_Files")}</Link>
                            </div>
                        </div>

                        <div className="user-profile dropdown">
                            <Link to="#" data-bs-toggle="dropdown" role="" className="text-decoration-none" aria-expanded="false">
                                <div className="wrapper wrapper2">
                                    <span>{t("global:Admin")}<i className="vvg-arrow-down"></i> </span>
                                </div>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-end">
                                
                                {JSON.parse(localStorage.getItem('user_info')) &&
                                JSON.parse(localStorage.getItem('user_info')).POSAdminFlag &&
                                JSON.parse(localStorage.getItem('user_info')).AccountingFlag && (
                                    <Link to="/payment/review" className="dropdown-item">
                                        <i className=""></i>{t("global:Payment_Review")}
                                    </Link>
                                )}
                                {/* <Link to="/payment/review" className="dropdown-item"><i className=""></i>Payment Review</Link> */}
                                <Link to="#" className="dropdown-item" onClick={this.handleShowPairModel}><i className=""></i>{t("global:Pairing_With_Linkly")}</Link>
                            </div>
                        </div>

                        <div className="user-profile dropdown">
                            <Link to="#" data-bs-toggle="dropdown" role="" className="text-decoration-none" aria-expanded="false">
                                <i className="vvg-account"></i>
                                <div className="wrapper">
                                    <span>
                                        {/* {this.truncateText(this.state.firstName, 10)}  */}
                                        {/* {this.state.lastName}  */}
                                    </span>
                                    {/* <span className="workspace-name"> */}
                                    <span className="">
                                        {this.truncateText(this.state.username, 10)}
                                        <i className="vvg-arrow-down"></i> 
                                    </span>
                                        
                                </div>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-end">
{/*                                 <a href="/profile" className="dropdown-item"><i className="vvg-profile"></i> My Profile</a> */}
                                <a onClick={this.handleLogout} className="dropdown-item"><i className="vvg-logout"></i>{t("global:Logout")}</a>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.showPairModel && <LinklyPairing 
                    fields={this.props} 
                    handleShowPairModel = {this.handleShowPairModel}
                    />
                }
                {
                    this.state.reciptReprint && <ReceiptReprint 
                    fields={this.props} 
                    handleReceiptReprintModal = {this.handleReceiptReprintModal}
                    />
                }
                {
                    this.state.checkLastTransModal && <CheckLastTransaction 
                    fields={this.props} 
                    handleCheckLastTrans = {this.handleCheckLastTrans}
                    />
                }
                {
                    this.state.fileAttachModal && <FileAttach 
                    fields={this.props} 
                    handleFileAttach = {this.handleFileAttach}
                    />
                }

            </header>

            <Outlet />
        </> 
        
    )
  }
}
export default withTranslation()(Header);

