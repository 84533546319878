// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-light text-dark mt-5">
      <div className="container py-4">
        <div className="row">
          <div className="col text-center">
            <p className="mb-0">Copyright © {(new Date().getFullYear())} VPOS. All rights reserved. Version {localStorage.getItem('tag_name')}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
