import React, {useEffect, useState} from 'react'
import {HTTP} from '../../api/http-common';
import Header from '../partials/Header'
import PaymentReviewInputs from './PaymentReviewInputs';
import CardPaymentsTran from './CardPaymentsTran';
import CashPaymentsTran from './CashPaymentTran';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import CustomPagination from './CustomPagination';
import PageSize from './PageSize';
import Footer from '../partials/Footer';


function PaymentReview()  {
    const [t, i18n] = useTranslation("global");
    
    const [resData, setResData] = useState([]);
    const [bgColor, setBgColor] = useState('#fff');
    const [removeBgColor, setRemoveBgColor] = useState('');
    const [invoiceTransbgColor, setInvoiceTransbgColor] = useState("#fff");
    const [removeInvoiceTransbgColor, setRemoveInvoiceTransbgColor] = useState("");
    const [refInvoiceNumber, setRefInvoiceNumber] = useState('');
    const [invoiceTrans, setInvoiceTrans] = useState('');
    const [cardPaymentData, setCardPaymentData] = useState('');
    const [cashPaymentData, setCashPaymentData] = useState('');
    const [inputs, setInputs] = useState({});
    const [lookupName, setLookupName] = useState('');
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        clearInputs()
      }, []);
    
    let dateFormat = process.env.REACT_APP_DATE_FORMAT;
    if(!(dateFormat)){
        dateFormat = 'MM/dd/yyyy'
    }

    const getCardPayment = (InvoiceSlsId, PaymentType, InvoicePaymentLogID, payment_trans_id = null) => {
        let url = null
        if(PaymentType=="CASH")
        {
            url = `payment-trans/cash-payment/${InvoicePaymentLogID}/${PaymentType}`
        }
        else
        {
            url = `payment-trans/card-payment/${InvoiceSlsId}/${PaymentType}`
        }
        if(payment_trans_id){
            url += `?payment_trans_id=${payment_trans_id}`
        }

        HTTP.get(url)
        .then(function (response) {
          if(response.status === 200){
            let data = response.data
            
            if(Object.keys(data).length === 0)
            {
                data = ''
            }

            if(PaymentType=="CASH")
            {
                setCashPaymentData(data)
                setCardPaymentData('')
                
            }
            else
            {
                setCardPaymentData(data)
                setCashPaymentData('')
            }
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    
    const handleremovediv = (idx, invoice_payment_log_id, PaymentNotes) => {

        setBgColor("#fff")
        if(removeBgColor){
           let rm_id = document.getElementById(`${removeBgColor}`)
            if (rm_id)
            {
             rm_id.style.backgroundColor = '#fff';
             rm_id.style.color = 'black';
            }
        }
        document.getElementById(`${idx}`).style.backgroundColor = '#076EB0';
        document.getElementById(`${idx}`).style.color = '#fff';
        setRemoveBgColor(idx)        

        HTTP.post(`payment/limited_payment_review`, {"invoice_payment_log_id": invoice_payment_log_id,"invoice_number": idx})
        .then(function (response) {
            if(response.status == 200){
                              
                setInvoiceTrans(response.data)
                
                if (response.data?.[0])
                {
                    let PaymentMedia = response.data[0].PaymentMedia
                    let PaymentType = response.data[0].PaymentType

                    if (PaymentMedia != "Attachment")
                    {
                        getCardPayment(idx, PaymentType, invoice_payment_log_id)
                    }
                    else
                    {
                        setCardPaymentData('')
                        setCashPaymentData('')
                    }

                    if(removeInvoiceTransbgColor){
                        let rm_id = document.getElementById(`${removeInvoiceTransbgColor}`)
                        if (rm_id)
                        {
                            rm_id.style.backgroundColor = '#fff';
                            rm_id.style.color = 'black';
                        }
                    }
   
                }
                else
                {
                    setCardPaymentData('')
                    setCashPaymentData('')
                }
                
            }
        })
        .catch(function (err) {
            console.log(err);
            
        });

        setRefInvoiceNumber(PaymentNotes) 
       
      };

    const handleShowTrans = (InvoiceSlsId, RandomID, PaymentType, PaymentMedia, InvoicePaymentLogID, payment_trans_id = null) => {
        
        setInvoiceTransbgColor("#fff")
        if(removeInvoiceTransbgColor){
           let rm_id = document.getElementById(`${removeInvoiceTransbgColor}`)
           if (rm_id)
           {
            rm_id.style.backgroundColor = '#fff';
            rm_id.style.color = 'black';
           }
        }
        document.getElementById(`${RandomID}`).style.backgroundColor = '#076EB0';
        document.getElementById(`${RandomID}`).style.color = '#fff';
        setRemoveInvoiceTransbgColor(RandomID)
        
        if (PaymentMedia != "Attachment")   // pending here for call with payment_type checking
        {
            getCardPayment(InvoiceSlsId, PaymentType, InvoicePaymentLogID, payment_trans_id)
        }
        else
        {
            setCardPaymentData('')
            setCashPaymentData('')
        }

    };      

  const handleChangeInputs = (event) => {

    const name = event.target.name;
    let value = event.target.value;
    setInputs(values => ({...values, [name]: value}))

  }
 
  const submitInputs = (event) => {
    inputs['page_size'] = pageSize
    // setInputs(inputs)
    if(!inputs['payment_amount'])
    {
        inputs['payment_amount'] = null
    }
        
    HTTP.post(`payment/payment_review`, inputs)
    .then(function (response) {
        if(response.status == 200){
            
            setResData(response.data.payment)
            setInvoiceTrans(response.data.payment_trans)
            if(response.data.payment[0]?.PaymentNotes)
            {
                setRefInvoiceNumber(response.data.payment[0].PaymentNotes) 
            }

            if(removeBgColor){
                let rm_id = document.getElementById(`${removeBgColor}`)
                 if (rm_id)
                 {
                  rm_id.style.backgroundColor = '#fff';
                  rm_id.style.color = 'black';
                 }
             }

            if(removeInvoiceTransbgColor){
                let rm_id = document.getElementById(`${removeInvoiceTransbgColor}`)
                if (rm_id)
                {
                    rm_id.style.backgroundColor = '#fff';
                    rm_id.style.color = 'black';
                }
            }

            let PaymentMedia = ""
            if (response.data.payment_trans[0])
            {
                PaymentMedia = response.data.payment_trans[0].PaymentMedia
            }
            else
            {
                setCardPaymentData('')
                setCashPaymentData('')
            }

            if (PaymentMedia != "Attachment")
            {
                if (response.data.payment_type)
                {
                    if (response.data.payment_type.PaymentType === "CASH")
                    {
                        setCardPaymentData("")
                        setCashPaymentData(response.data.payment_type)
                    }
                    else
                    {
                        setCardPaymentData(response.data.payment_type)
                        setCashPaymentData("")
                    }  
                }
            }
            else
            {
                setCardPaymentData('')
                setCashPaymentData('')
            }
        }
    })
    .catch(function (err) {
        console.log(err);
        
    });

  }

  const clearInputs = (event) => {
    setInputs({})
    setInputs(values => ({...values, ['from_date']: ""}))
    setInputs(values => ({...values, ['to_date']: ""}))
    setLookupName("")
  }
  
    return (
      <>

        <Header />
        <div className="page-content">
            <div className="container-fluid">

                <div className="row mt-0">
                    <div className="col-lg-6 col-md-12 mb-3">

                        <div className=" mt-3">
                            <div className="invoice-table primary-table table-responsive-sm" style={{width:"100%", overflowX:"auto", height:"300px", overflowY:"auto"}}>
                               
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th className="text-center">{t("Customer")} #</th>
                                            <th className="text-center">{t("Invoice")} #</th>
                                            <th className="text-end">{t("Invoice Amt")}</th>
                                            <th className="text-end">{t("Total Paid Amt")}</th>
                                            <th className="text-end">{t("Invoice Date")}</th>
                                            <th className="text-end">{t("Payment Branch")}</th>
                                            <th className="text-end">{t("Department")}</th>
                                            <th className="text-end">{t("Ref. Invoice")} #</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                     {
                                     resData?
                                            
                                     resData.map(data => (
                                        <tr className='removeColor' style={{backgroundColor: bgColor}} 
                                        id={data.InvoiceNumber} onClick={()=>handleremovediv(data.InvoiceNumber, data.InvoicePaymentLogID, data.PaymentNotes)} >
                                            <td className="text-center">{data.CustomerNumber}</td>
                                            <td className="text-center">{data.InvoiceNumber}</td>
                                            <td className="text-end">{data.InvoiceTotalAmt ? '$' + parseFloat(data.InvoiceTotalAmt).toFixed(2) : "$0.00"}</td>
                                            <td className="text-end">{data.TotalPaidAmt ? '$' + parseFloat(data.TotalPaidAmt).toFixed(2) : "$0.00"}</td>

                                            <td className="text-center">{data.InvoiceDate ? moment(new Date(data.InvoiceDate)).format(dateFormat.toUpperCase()) : '' }</td>
                                            <td className="text-center">{data.BranchName}</td>
                                            <td className="text-center">{data.PaymentDepartment}</td>
                                            <td className="text-center">{data.RefInvoiceNumber}</td>

                                        </tr>
                                        
                                      ))  
                                      
                                      : ""
                                    }
                                    </tbody>
                                </table>
                            </div>
                            
                            <div className="row mt-1">

                                {resData.length != 0 ?

                                <div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center'}}>
                                    <PageSize pageSize={pageSize} totalRows={resData[0]['TotalRows']} setPageSize={setPageSize}></PageSize>

                                    <CustomPagination 
                                    totalRows={resData[0]['TotalRows']} 
                                    inputs={inputs} 
                                    submitInputs={submitInputs} 
                                    pageSize={pageSize}
                                    setInputs={setInputs} 
                                    calledFrom="paymentReview" />
                                </div>

                                :""
                                }

                            </div>

                            <div className="mt-2">
                                <input type="text" id="ref_invoice" className="form-control" value={refInvoiceNumber}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                    
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <form action="#">
                                    <div className="other-payment-mode section-boxes">
                                        
                                        {
                                        
                                        <PaymentReviewInputs 
                                        key = {1}
                                        id = {1}
                                        inputs={inputs}
                                        setInputs={setInputs}
                                        handleChangeInputs={handleChangeInputs}
                                        lookupName = {lookupName}
                                        setLookupName = {setLookupName}
                                        
                                        />

                                        }

                                        <div className="text-center mt-3">
                                            <button type="button" className="btn btn-primary save-payment mx-3 mb-2" onClick={submitInputs}>{t("SEARCH")}</button>
                                            <button type="reset" className="btn btn-primary save-payment mb-2" onClick={clearInputs}>{t("Clear")}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <div className="section-boxes" style={{height:"386px", overflowY:"auto"}}>
                                <table className="table table-borderless" >

                                {
                                    
                                    !cardPaymentData == '' ? 
                                        <CardPaymentsTran 
                                        key = {1}
                                        id = {1}
                                        cardPaymentData={cardPaymentData}
                                    />
                                    : 
                                        !cashPaymentData == '' ? 
                                        <CashPaymentsTran 
                                            key = {1}
                                            id = {1}
                                            cashPaymentData={cashPaymentData}
                                        />
                                    :""

                                }

                                </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="invoice-payment-table mt-3 bg-white primary-table table-responsive-sm" style={{width:"100%", overflowX:"auto"}}>
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th className="text-center">{t("Invoice")} #</th>
                                <th className="text-end">{t("Seq")} #</th>
                                <th className="text-end">{t("Pymt Type")}</th>
                                <th className="text-end">{t("Pymt Media")}</th>
                                <th className="text-end">{t("Reference")} #</th>
                                <th className="text-end">{t("Total Trans Amt")}</th>
                                <th className="text-end">{t("Amt Applied To")}</th>

                                <th className="text-end">{t("Cash Received")}</th>
                                <th className="text-end">{t("Change Due")}</th>
                                <th className="text-end">{t("Payment Processed By")}</th>
                                <th className="text-end">{t("Payment Date")}</th>
                                <th className="text-end">{t("Payment Modified By")}</th>
                                <th className="text-end">{t("Payment Modified Date")}</th>
                                <th className="text-end">{t("Customer Number")}</th>
                                <th className="text-end">{t("Journal Entry ID")}</th>
                                <th className="text-end">{t("POS Trans ID")}</th>
                                <th className="text-end">{t("Tax Amt")}</th>
                                <th className="text-end">{t("GL Code")}</th>
                                <th className="text-end">{t("Processor")}</th>
                                <th className="text-end"># {t("of Invoice")}</th>
                                <th className="text-end">{t("List Invoices")}</th>
                                <th className="text-end">{t("Surchargeamt")}</th>
                                <th className="text-end">{t("Surchargepct")}</th>
                                
                            </tr>
                        </thead>
                        <tbody>

                            {

                            invoiceTrans ?
                            invoiceTrans.map((transData, index)=> (
                            
                            <tr style={{backgroundColor: invoiceTransbgColor}} id={"786" + index} onClick={()=>handleShowTrans(transData.InvoiceNumber, "786" + index , transData.PaymentType, transData.PaymentMedia, transData.InvoicePaymentLogID, transData.PaymentTransID)}>
                                <td className="text-center">{transData.InvoiceNumber}</td>
                                <td className="text-end">{transData.PaymentSeqNumber}</td>
                                <td className="text-end">{transData.PaymentType}</td>
                                <td className="text-end">{transData.PaymentMedia}</td>
                                <td className="text-end">{transData.ReferenceNumber}</td>
                                <td className="text-end">{transData.TotalTransAmt ? '$' + parseFloat(transData.TotalTransAmt).toFixed(2) : ""}</td>
                                <td className="text-end">{transData.AmtAppliedToThisInvoice ? '$' + parseFloat(transData.AmtAppliedToThisInvoice).toFixed(2) : ""}</td>

                                <td className="text-end">{transData.CashReceived ? '$' + parseFloat(transData.CashReceived).toFixed(2) : ""}</td>
                                <td className="text-end">{transData.ChangeDue ? '$' + parseFloat(transData.ChangeDue).toFixed(2) : ""}</td>
                                <td className="text-end">{transData.EnteredByUserId}</td>
                                <td className="text-end">{transData.EnteredDate ? moment(new Date(transData.EnteredDate)).format(dateFormat.toUpperCase() + ' hh:mm A') : '' }</td>
                                <td className="text-end">{transData.ModifiedByUserId}</td>
                                <td className="text-end">{transData.ModifiedDate ? moment(new Date(transData.ModifiedDate)).format(dateFormat.toUpperCase() + ' hh:mm A') : '' }</td>
                                <td className="text-end">{transData.CustomerNumber}</td>
                                <td className="text-end">{transData.JournalEntryID}</td>
                                <td className="text-end">{transData.PaymentTransID}</td>
                                <td className="text-end">{transData.TaxAmt ? parseFloat(transData.TaxAmt).toFixed(2) : ""}</td>
                                <td className="text-end">{transData.GLCode}</td>
                                <td className="text-end">{transData.Processor}</td>
                                <td className="text-end">{transData.NumOfInvoiceThisTransApplied}</td>
                                <td className="text-end">{transData.ListOfInvoiceThisTransApplied}</td>
                                <td className="text-end">{transData.SurchargeAmt ? '$' + parseFloat(transData.SurchargeAmt).toFixed(2) : ""}</td>
                                <td className="text-end">{transData.SurchargePct ? parseFloat(transData.SurchargePct).toFixed(4) : ""}</td>
                            </tr>

                            ))
                            : ""
                            
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <Footer />
        
      </>
    );
  
};

export default PaymentReview;
