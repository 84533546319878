import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";


function CashPayment(props) {
    const [t, i18n] = useTranslation("global");
    const [changeDue, setChangeDue] = useState();
    const [okButton, setOkButton] = useState("None");
    const [changeAmtText, setChangeAmtText] = useState('Amount Short');


    const handleChangesetCashReceived = (event) => {
        if(event.target.value){
            props.setCashReceived(event.target.value)
        }
        else
        {
            props.setCashReceived(null)
        }
        const calAmt = Math.abs(parseFloat(props.paymentAmount) - parseFloat(event.target.value)).toFixed(2)
        if (calAmt){
            setChangeDue(calAmt)
        }
        else if (calAmt == 0){
            setChangeDue("")
        }

        if (!event.target.value){
            setChangeDue(props.paymentAmount)
        }

        if (parseFloat(event.target.value) == parseFloat(props.paymentAmount))
        {
            setChangeAmtText('Change Due')
            setOkButton("")
        }
        else if (parseFloat(event.target.value) > parseFloat(props.paymentAmount))
        {
            setChangeAmtText('Change Due to Customer')
            setOkButton("")
        }
        else
        {
            setChangeAmtText('Amount Short')
            setOkButton("None")
        }
    }
    
  const handleClose = () => {
    props.setHideShowModal(false);
    props.setCashReceived(null)
  }

  return (
    
      <Modal
        show={props.hideShowModal}
        onHide={false}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>{t("Cash Payment")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
            <div className="payment-form mt-0" style={{ display: props.cashPaymentConfirmation }}>
                                
                <div className="form-group mb-2">
                    <label htmlFor='form-label' className="form-label">{t("Payment Amount")} {t("$")}:</label>
                    <input readOnly={true} type="text" className="form-control" name="paymentAmt" value={props.paymentAmount}/>
                </div>

                <div className="form-group mb-2">
                    <label htmlFor='form-label' className="form-label">{t("Cash Received")} {t("$")}:</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="cashReceived" 
                        value={props.cashReceived} 
                        onChange={handleChangesetCashReceived}
                        onInput={(e) => {
                            // Remove non-numeric characters except for the decimal point
                            e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                            
                            // Ensure there's only one decimal point
                            const parts = e.target.value.split('.');
                            if (parts.length > 2) {
                                e.target.value = parts[0] + '.' + parts.slice(1).join('');
                            }
                            
                            // Limit to 4 digits before the decimal point
                            if (parts[0].length > 7) {
                                e.target.value = parts[0].slice(0, 7) + '.' + (parts[1] || '');
                            }
                            
                            // Limit to 2 digits after the decimal point
                            if (parts[1] && parts[1].length > 2) {
                                e.target.value = parts[0] + '.' + parts[1].slice(0, 2);
                            }
                        }} 
                    />
                </div>

                <div className="form-group">
                    <label className="form-label">{t(changeAmtText)} {t("$")}:</label>
                    <input readOnly={true} type="text" className="form-control" name="changeDue"  value={changeDue}/>
                </div>
            
                
            </div>


            <div className="payment-form mt-0" style={{ display: props.cashDueToCustomer }}>
                
                <div className="form-group mb-2 text-center">
                    <label htmlFor='form-label' className="form-label "><h4>{t("Cash Due to Customer")}:</h4></label>
                    <input type="text" className="form-control text-center" name="paymentAmt" value={props.paymentAmount}/>
                </div>
                
            </div>

        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>

        <div className="row">
            <div className="col">
            <button type="button" className="btn btn-primary mb-4 mx-5" onClick={handleClose}>{t("BACK")}</button> 
            <button type="button" style={{ display: props.cashDueToCustomer == "" ? "" : okButton }} onClick={props.handleCashPayment} className="btn btn-primary pull-right mb-4 mx-5" >OK</button> 
            </div>
        </div>

      </Modal>
    
  );
}

export default CashPayment;


CashPayment.propTypes = {
    setHideShowModal: PropTypes.any,
    setCashReceived: PropTypes.any,
    paymentAmount: PropTypes.any,
    hideShowModal: PropTypes.any,
    cashPaymentConfirmation: PropTypes.any,
    cashDueToCustomer: PropTypes.any,
    cashReceived: PropTypes.any,
    handleCashPayment: PropTypes.any,
  };
