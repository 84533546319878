import React, { useState, useEffect } from 'react';

const Timer = ({setProcessButton, setLastTransactionButton, resetTimer, stopTimer}) => {
  const [seconds, setSeconds] = useState(180); // 3 minutes in seconds
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {

      if(resetTimer){
        setIsActive(false);
        setSeconds(180);
      }
      if(stopTimer){
        setIsActive(false);
        setSeconds(0);
      }


  }, [resetTimer, stopTimer]

);

  

  useEffect(() => {
    let interval = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      setIsActive(false);
      setProcessButton("None")
      setLastTransactionButton("")
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, setProcessButton, setLastTransactionButton]);


  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <>
      <span className='btn'>Remaining Time: {isActive || seconds > 0 ? formatTime(seconds) : '00:00'}</span>
    </>
  );
};

export default Timer;


