import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { HTTP } from '../../api/http-common';
import CardPaymentsTran from './CardPaymentsTran';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import Timer from './CountdownTimer';
import CheckLastTransaction from '../pages/CheckLastTransaction';



function CardPayment(props) {
    const [t, i18n] = useTranslation("global");
    const [processButton, setProcessButton] = useState(false);
    const [startProcessClicked, setStartProcessClicked] = useState(false);
    const [lastTransactionButton, setLastTransactionButton] = useState("None");
    const [checkLastTransModal, setCheckLastTransModal] = useState(false);
    const [conformMessage, setConformMessage] = useState('')
    const [displayText, setDisplayText] = useState("Please wait...");
    const [cardPaymentData, setCardPaymentData] = useState();
    const [alertComplete, setAlertComplete] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [isCardAmex, setIsCardAmex] = useState(false)
    const alertHandleClose = () => setAlertShow(false);
    const alertCompleteClose = () => setAlertComplete(false)
    const handleClose = () => props.setHideShowCardModal(false);
    const [showSurcharge, setShowSurcharge] = useState(false);
    const [actionCancel, setActionCancel] = useState(false);
    const [actionMultiInvoice, setActionMultiInvoice] = useState(false);
    const [surchargeAmt, setSurchargeAmt] = useState(0);
    const [paymentTypeText, setPaymentTypeText] = useState("Debit Card Payment");
    const [startTimer, setStartTimer] = useState(false);
    const [resetTimer, setResetTimer] = useState(false);
    const [stopTimer, setStopTimer] = useState(false)
    const [alertProcessing, setAlertProcessing] = useState(false)

    const finalCloseModel = () => {
        if(actionMultiInvoice)
        {
            let multi_invoice_list = localStorage.getItem('invoice_list')
            multi_invoice_list = multi_invoice_list.split(",")
            
            props.getInvoiceListAPI(multi_invoice_list, false, false)
        }
        if(actionCancel)
        {
            handleSubmitCardPayment("CANCEL", true, true, isCardAmex, true)
            console.log("cancelled")
        }
        if(!(actionCancel))
        {
            handleClose()
        }
    }

    const getCardPayment = async(InvoiceSlsId, PaymentType, InvoicePaymentLogID) => {
        let url = null
        if (PaymentType == "CASH") {
            url = `payment-trans/cash-payment/${InvoicePaymentLogID}/${PaymentType}`
        }
        else {
            url = `payment-trans/card-payment/${InvoiceSlsId}/${PaymentType}`
        }

        await HTTP.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    const data = response.data
                    setCardPaymentData(data)
                    setAlertProcessing(false)    
                    setAlertComplete(true)             
                }
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    useEffect(() => {
        // let paymentRefund = false
        if (props.paymentAmount < 0) {
            // paymentRefund = true
            setPaymentTypeText("Refund Card Payment")
        }
        else if (props.paymentMethod === 'CREDIT')
        {
            setPaymentTypeText("Credit Card Payment")
        }
        else
        {
            setPaymentTypeText("Debit Card Payment")
        }

      }, [1]);



    const handleAskAmex = async (amex_card) =>{

        let askCardAmex = true
        let amexCardVal = amex_card
        let askSurchargeVal = false

        setIsCardAmex(amex_card)
        setAlertShow(false)
        
        handleSubmitCardPayment("", true, askCardAmex, amexCardVal, askSurchargeVal)
        
    }

    const handleAskSurcharge = async (confirmVal) => {

        setShowSurcharge(false)
        if(confirmVal)
        {
            setActionCancel(true)
            handleSubmitCardPayment("", true, true, isCardAmex, confirmVal)
        }
        
    }

    const handleStartProcess = () =>{
        setStartProcessClicked(true)
        handleSubmitCardPayment("", true, false, false, false)
    }


    const handleSubmitCardPayment = async (action, start_process, askCardAmex, amexCardVal, askSurchargeVal, invoice_list_final=null, final_payment_amt=null) => {
        
        if (action == 'SALE' && props.paymentAmount < 0) {
            action = 'REFUND'
        }
        let url = null

        let payment_amt = props.paymentAmount
        let invoice_list_data = props.getInvoice
        if(invoice_list_final)
        {
            payment_amt = final_payment_amt
            invoice_list_data = invoice_list_final
        }

        if (props.paymentMethod === 'CREDIT') {
            if (!askCardAmex) {
                setAlertShow(true)
                return
            }
            else
            {
                url = '/credit_card_payment'
            } 
        }
        else {
            url = '/debit-card-payment'
        }
        let multi_invoice_list = localStorage.getItem('invoice_list')
        // let async_trans = process.env.REACT_APP_ASYNC_TRANS  // this is use for manage async value from front end env file
        let request_data = {
            "workstation_name": localStorage.getItem('workstation'),
            "current_row": props.currentRow,
            "action": action,
            "payment_amt": payment_amt,
            "estimate_not_invoice": "yes",
            "payment_trans_id": 0,
            "payment_method": props.paymentMethod,
            "payment_media": "",
            "account_number": "",
            "trans_type": props.paymentMethod,
            "term_id": "",
            // "async_trans": async_trans, // this is use for manage async value from front end env file
            "start_process": start_process,
            "invoice_number": multi_invoice_list,
            "is_card_amex": amexCardVal,
            "invoice_list": [invoice_list_data],
            "is_surcharge_amt_confirm": askSurchargeVal
            
        }

        await HTTP.post(url, request_data)
            .then(function (response) {

                // sync transaction

                if (response.status == 200) {
                    const data = response.data
                    console.log("success response " + data)

                    if (data.ask_confirm) {
                        setSurchargeAmt(data.surcharge_amt)
                        console.log("ask as_confirm")
                        setConformMessage(data.ask_confirm)
                        setShowSurcharge(true)
                        

                    }

                    if (data.process == "Completed") {
                        console.log("process status " + data.process)

                        if (props.paymentMethod === 'CREDIT')
                        {
                            let multi_invoice_list = localStorage.getItem('invoice_list')
                            multi_invoice_list = multi_invoice_list.split(",")
                                                              
                            let invoice_list = {"invoices": multi_invoice_list}
                            HTTP.post('invoice/get-multiple-invoices', invoice_list)
                            .then(function (response) {
                            if(response.status === 200){
                                let payment_amt = parseFloat(props.paymentAmount) + parseFloat(surchargeAmt)
                                payment_amt = parseFloat(payment_amt).toFixed(2)
                                props.setPaymentAmount(payment_amt)
                                console.log(response.data)
                                props.setGetInvoice(response.data)

                                handleSubmitCardPayment("SALE", true, true, isCardAmex, true, response.data, payment_amt)

                            }      
                            })
                            .catch(function (err) {
                            console.log(err);
                            });
                        }
                        else{
                            handleSubmitCardPayment("SALE", true, true, isCardAmex, true)
                        }

                        setStartTimer(true)

                    }
                    else if (data.detail || data.message) {
                        console.log("invoice payment " + data.detail)
                        setStartTimer(false)
                        setResetTimer(true)
                        setDisplayText(data.detail)
                        setProcessButton("None")

                        let multi_invoice_list = localStorage.getItem('invoice_list')
                        multi_invoice_list = multi_invoice_list.split(",")

                        if(data.detail === 'Card Payment Successfull!'){
                            // props.getInvoiceListAPI(multi_invoice_list, false, false)
                            setActionCancel(false)
                            setActionMultiInvoice(true)
                            const invoiceNumber = multi_invoice_list[0];
                            getCardPayment(invoiceNumber, 'CREDIT', '')  
                        }
                        else if(data.message === "Payment cancel success")
                        {
                            props.getInvoiceListAPI(multi_invoice_list, false, false)
                            handleClose()
                        }
                        else
                        {
                            props.paymentMethod === 'CREDIT' ? setActionCancel(true) : setActionCancel(false)
                        }
                    }
                    
                }


                // async transaction


                if (response.status == 202) {
                    const sessionId = response.data.session;
                    const token = response.data.token;
                    const invoiceNumber = localStorage.getItem('invoice_list');
                    const userInfo = JSON.parse(localStorage.getItem('user_info'));
                    const userid = userInfo.UserId


                    const socket = new WebSocket(`${process.env.REACT_APP_WS_URL}/linkly_payment_gateway/ws/${sessionId}?invoice_number=${invoiceNumber}&userid=${userid}&auth_code=${token}`)
                    console.log("socket is.......", socket)

                    socket.addEventListener('open', (event) => {
                        console.log('WebSocket connection opened:', event);
                    });


                    socket.addEventListener('message', (event) => {
                        console.log('Message from server:', event.data);
                        const jsonObject = JSON.parse(event.data)
                        const responseObject = jsonObject.Response

                        if (jsonObject.ResponseType == 'display') {
                            let pinpad_text = jsonObject.Response.DisplayText.join(' ').trim()
                            setDisplayText(jsonObject.Response.DisplayText.join(' '))

                            const pinpadStates = [
                                "OPERATOR TIMEOUT",
                                "PINpad Busy",
                                "OPERATOR CANCELLED",
                                "APPROVED",
                                "PINpad Offline",
                                "SYSTEM ERROR"
                            ];

                            if (pinpadStates.includes(pinpad_text)){
                                setProcessButton("None")
                                setStopTimer(true)
                                setStartTimer(false)
                                setResetTimer(true)
                            }
                            if(pinpad_text === 'APPROVED'){
                                setAlertProcessing(true)
                            }
                        }

                        if (jsonObject.ResponseType == 'transaction') {
                            if (jsonObject.Response.Success && jsonObject.Response.ResponseCode == '00') {

                                let request_data = {
                                    "workstation_name": localStorage.getItem('workstation'),
                                    "current_row": props.currentRow,
                                    "action": action,
                                    "payment_amt": payment_amt,
                                    "estimate_not_invoice": "yes",
                                    "payment_trans_id": parseInt(responseObject.TxnRef),
                                    "payment_method": props.paymentMethod,
                                    "payment_media": responseObject?.CardType ?? '',
                                    "account_number": responseObject?.Pan ?? '',
                                    "trans_type": props.paymentMethod,
                                    "term_id": responseObject?.CatId ?? '',
                                    // "async_trans": "true",  // this is use for manage async value from front end env file
                                    "start_process": false,
                                    "invoice_number": invoice_list_data.invoices[0].InvoiceNumber,
                                    "invoice_list": [invoice_list_data]
                                }
                                let url = null

                                if (props.paymentMethod === 'CREDIT') {
                                        url = '/credit_card_payment'
                                    }

                                else {
                                    url = '/debit-card-payment'
                                }

                                HTTP.post(url, request_data)
                                    .then(function (response) {
                                        console.log("Card Payment " + response.data)
                                        
                                    })
                                    .catch(function (err) {
                                        console.log(err);
                                    });



                                getCardPayment(invoiceNumber, props.paymentMethod, '')
                                setActionMultiInvoice(true)
                                setActionCancel(false)
                                setStartTimer(false)
                                setResetTimer(true)
                                setProcessButton("None")
                                                                

                            }
                            
                        }


                        socket.send(event.data)


                        console.log("sending........")

                    });


                    socket.addEventListener('error', (event) => {
                        // Handle WebSocket errors
                        console.log(event)
                        alert('WebSocket Error:');
                    });



                    socket.addEventListener('close', (event) => {
                        console.log('WebSocket connection closed:', event);
                    });
                }
            })

            .catch(function (err) {
                console.log(err);
                if (err.response?.data?.detail) {
                    alert(err.response.data.detail)
                    setStartTimer(false)
                    setResetTimer(true)
                }
            });
    }

 
    const handleCheckLastTrans = async () =>{
        console.log(" check last trns")
        setCheckLastTransModal(true)
        setLastTransactionButton("None")
        // finalCloseModel()
    }

    return (
        <div>

            <Modal
                show={props.hideShowCardModal}
                onHide={handleClose}
                backdrop="static"
                // fullscreen={true}
                size="lg"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>{t(paymentTypeText)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="payment-form mt-0">
                        <div className="row mt-0">
                            <div className="col-lg-6 col-md-12 mb-3">

                                <div className="row mt-0">

                                    <div className="col-md-12 mb-3">

                                        <div className="row g-3 align-items-center mb-3">
                                            <div className="col-5">
                                                <label htmlFor="inputPassword6" className="col-form-label"><b>{t("Invoice")} #:</b> </label>
                                            </div>
                                            <div className="col-auto">
                                                <input type="text" id="inputPassword6" readOnly className="form-control" value={props.getInvoice.invoices[0].InvoiceNumber} aria-describedby="passwordHelpInline" />
                                            </div>
                                        </div>

                                        <div className="row g-3 align-items-center mb-3">
                                            <div className="col-5">
                                                <label htmlFor="inputPassword6" className="col-form-label"><b>{t("Payment Amount")} {t("$")}:</b> </label>
                                            </div>
                                            <div className="col-auto ">
                                                <input type="text" id="inputPassword6" readOnly className="form-control text-end" value={props.paymentAmount? parseFloat(props.paymentAmount).toFixed(2) : "0.00"} aria-describedby="passwordHelpInline" />
                                            </div>
                                        </div>

                                        <div className="row g-3 align-items-center mb-3">
                                            <div className="col-5">
                                                <label htmlFor="inputPassword6" className="col-form-label"><b>{t("Workstation name")}:</b> </label>
                                            </div>
                                            <div className="col-auto ">
                                                <input type="text" id="inputPassword6" readOnly className="form-control" value={localStorage.getItem('workstation')} aria-describedby="passwordHelpInline" />
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row mt-0 mt-3">

                                    <div className="col-md-12 m-1 section-boxes">
                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                            {displayText}
                                        </label>
                                        
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-6 col-md-12">

                                <div className="section-boxes" style={{ height: "308px", overflowY: "auto" }}>
                                    <table className="table table-borderless" >
                                        {

                                            !cardPaymentData == '' ?
                                                <CardPaymentsTran
                                                    key={1}
                                                    id={1}
                                                    cardPaymentData={cardPaymentData}
                                                />
                                                    : ""

                                        }

                                    </table>

                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <hr/> */}

                </Modal.Body>
                {/* <Modal.Footer> */}
                    {/* <Button variant="secondary" className="pull-left" onClick={handleClose}>BACK</Button>
          <Button variant="primary" style={{ display: okButton }} onClick={props.handleCashPayment}>OK</Button> */}
                {/* </Modal.Footer> */}

                <div className="row mx-4">
                    <div className="col">
                        
                        {
                            !alertComplete && 
                            <button type="button" onClick={finalCloseModel} className="btn btn-primary mb-4">
                            {t("BACK")}
                        </button>
                        
                        }
                    </div>
                        
                        <div className="col text-center">
                        {
                            startTimer && <Timer 
                                    setProcessButton = {setProcessButton}
                                    setLastTransactionButton = {setLastTransactionButton}
                                    resetTimer = {resetTimer}
                                    stopTimer = {stopTimer}
                                />
                        }
                        </div>

                        <div className="col">
                            <Button type="button" disabled = {startProcessClicked} style={{ display: processButton }} onClick={() => handleStartProcess()} className="btn btn-primary pull-right mb-4">
                                {t("Start Process")}
                            </Button>

                            
                            <Button type="button" style={{ display: lastTransactionButton }} onClick={() => handleCheckLastTrans() } className="btn btn-primary pull-right mb-4">
                                {t("Check Status")}
                            </Button>
                        
                        </div>

                    {/* </div> */}
                </div>
            </Modal>
            <Modal 
                show={alertComplete}
                onHide={alertCompleteClose} 
                animation={false} size='sm' 
                centered
                backdrop="static"
                keyboard={false}
                autofocus={true}
            >
                <Modal.Body>
                    <h3>{t('Payment Completed')}</h3>
                </Modal.Body>
                <Modal.Footer>
                <button type="button" onClick={finalCloseModel} className="btn btn-primary mb-2">
                            {t('OK')}
                        </button>
                </Modal.Footer>
            </Modal>
            <Modal 
                show={alertProcessing}
                animation={false} size='sm' 
                centered
                backdrop="static"
                keyboard={false}
                autofocus={true}
            >
                <Modal.Body>
                    <h3>{t('Processing')} ....</h3>
                </Modal.Body>
            </Modal>

            <Modal 
                show={alertShow}
                onHide={alertHandleClose} 
                animation={false} size='sm' 
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <p>{t("Please confirm if the Customer's card is AMEX!")}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleAskAmex(true)} size='sm'>
                        {t('Yes')}
                    </Button>
                    <Button variant="secondary" onClick={() => handleAskAmex(false)} size='sm'>
                        {t('No')}
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal 
                show={showSurcharge} 
                onHide={false} 
                animation={false} 
                size='sm' 
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <p>{conformMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleAskSurcharge(true)} size='sm'>
                        {t('Yes')}
                    </Button>
                    <Button variant="secondary" onClick={() => handleAskSurcharge(false)} size='sm'>
                        {t('No')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {
                    checkLastTransModal && <CheckLastTransaction 
                    handleCheckLastTrans = {handleCheckLastTrans}
                    />
            }

        </div>
    );
}

export default CardPayment;


CardPayment.propTypes = {
    getInvoice: PropTypes.any,
    setHideShowCardModal: PropTypes.any,
    getInvoiceListAPI: PropTypes.any,
    paymentAmount: PropTypes.any,
    paymentMethod: PropTypes.any,
    hideShowCardModal: PropTypes.any,
    setPaymentAmount: PropTypes.any,
    setGetInvoice: PropTypes.any,
    currentRow: PropTypes.any,

  };
