import AutoLogout from "./AutoLogout";
import PropTypes from 'prop-types';

const Layout = ({ children }) => {
    return (
      <div>
        <AutoLogout />
        {children}
      </div>
    );
  };

  Layout.propTypes = {
    children: PropTypes.any,
  };

  

  export default Layout;

