import React, {useEffect, useState, useCallback} from 'react'
import {HTTP} from '../../api/http-common';
import Header from '../partials/Header'
import CreateOtherPayment from './CreateOtherPayment';
import CardPaymentsTran from './CardPaymentsTran';
import CashPaymentsTran from './CashPaymentTran';
import CashPayment from './CashPayment'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import CardPayment from './CardPayment';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import MyContext from './MyContext';
import Footer from '../partials/Footer';


function Dashboard()  {
    const [t, i18n] = useTranslation("global");
    
    const [inputValue, setInputValue] = useState('');
    const [resData, setResData] = useState([]);
    const [getInvoice, setGetInvoice] = useState('');
    const [bgColor, setBgColor] = useState('#fff');
    const [removeBgColor, setRemoveBgColor] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isHideDiv, setIsHideDiv] = useState(false);
    const [changeText, setChangeText] = useState("Save");
    const [invoiceTransbgColor, setInvoiceTransbgColor] = useState("#fff");
    const [removeInvoiceTransbgColor, setRemoveInvoiceTransbgColor] = useState("");
    const [refInvoiceNumber, setRefInvoiceNumber] = useState('');
    const [cardPaymentData, setCardPaymentData] = useState('');
    const [cashPaymentData, setCashPaymentData] = useState('');
    const [createRefund, setCreateRefund] = useState(false);
    const [changeRefundText, setChangeRefundText] = useState("Create Refund");
    const [changeTypeText, setChangeTypeText] = useState("Ref #");
    const [inputs, setInputs] = useState({});
    const [changeTypeValue, setChangeTypeValue] = useState("");
    const [dealDisplay, setDealDisplay] = useState("none");
    const [paymentAmount, setPaymentAmount] = useState();
    const [checkEnableButton, setCheckEnableButton] = useState({});
    const [currentRow, setCurrentRow] = useState(0)
    const [isCheckedOtherPayment, setIsCheckedOtherPayment] = useState(false)

    const handle = useFullScreenHandle();


    useEffect(() => {
        localStorage.removeItem("invoice_list"); // localStorage.clear();
      }, []);
    
    let dateFormat = process.env.REACT_APP_DATE_FORMAT;
    if(!(dateFormat)){
        dateFormat = 'MM/dd/yyyy'
    }
    
    const getInvoiceListAPI = useCallback(async (send_all_invoice_list, remore, refund) => {
        let invoice_list = {"invoices": send_all_invoice_list}
        await HTTP.post('invoice/get-multiple-invoices', invoice_list)
        .then(function (response) {
          if(response.status === 200){

            const data = response.data.invoices
            setResData(data)
            setIsCheckedOtherPayment(true)
            setGetInvoice(response.data)
            setPaymentAmount(parseFloat(response.data.short_amt).toFixed(2))
            localStorage.setItem('invoice_list', send_all_invoice_list);
            
            if(data[0]?.invoice_trans[0])
            {   
                let invoiceTrans = data[0].invoice_trans
                for(let row = 0; row < invoiceTrans.length; row++){
                    if (invoiceTrans[row].PaymentMedia !== "Attachment")
                    {
                        setCurrentRow(row)
                        getCardPayment(invoiceTrans[row].InvoiceNumber, invoiceTrans[row].PaymentType, invoiceTrans[row].InvoicePaymentLogID, null, 0, true)
                    }
                }
            }
            else
            {
                setCashPaymentData('')
                setCardPaymentData('')
            }
            
            handleCheckEnableButton(response.data, 0)
            
            if(removeInvoiceTransbgColor){
                let rm_id = document.getElementById(`${removeInvoiceTransbgColor}`)
                if (rm_id)
                {
                    rm_id.style.backgroundColor = '#fff';
                    rm_id.style.color = 'black';
                }
            }

            //this neet to check
            // if(!(refund))
            // {
            //     setChangeRefundText("Create Refund")
            // }
            let TotalPriceAmt = data[0].TotalPriceAmt ? parseFloat(data[0].TotalPriceAmt) : 0.0
            let TotalPaidAmt = data[0].TotalPaidAmt ? parseFloat(data[0].TotalPaidAmt) : 0.0
            if(TotalPriceAmt !== 0 && TotalPaidAmt !== 0 && TotalPriceAmt === TotalPaidAmt)
            {
                setChangeRefundText("Create Refund")
            }
            else
            {
                setChangeRefundText("Create/Update")
            }

            if (!(remore)){
                data.map(data_obj => (
                    data_obj.RefInvoiceNumber ?
                    setRefInvoiceNumber(data_obj.RefInvoiceNumber) :
                    setRefInvoiceNumber("") 
                ))
        
                setCreateRefund(true)
                setIsHideDiv(true)
                // setChangeText('Save')
            }
            else
            {
                setRefInvoiceNumber("")
                console.log("invoice removed")
            } 

          }      
        })
        .catch(function (err) {
          console.log(err);
          if (err.response.data.detail)
            {   
                alert(err.response.data.detail)
            }
            else
            {
                alert("This is an invalid Invoice. Please make sure the Invoice Number is correct, and try again.")
            }
          return false
        });
    },[])

    const handleCreateRefund = () => {
        if (resData.length > 1)
        {
            alert("You can only create one POS Refund Invoice per original POS Invoice. Please correct it before processing.")
            return false
        }
        else if (resData.length < 1)
        {   
            // return false
        }
        
        let data_inputs = inputs
        let url = ""

        if (isHideDiv && changeRefundText == "Create/Update")
        {
            url = "create-other-payment/"
        }
        else
        {
            data_inputs = {
                'invoice_id': getInvoice.invoices[0].InvoiceNumber,
                'cust_number': getInvoice.invoices[0].CustomerId,
                'ref_number': getInvoice.invoices[0].InvoiceNumber,
                'amount': getInvoice.invoices[0].SubtotalAmt,
                'notes': getInvoice.invoices[0].Notes,
                'deal_number': getInvoice.invoices[0].DealNumber,
                // 'ref_number': getInvoice.invoices[0].ReferenceNumber,
            }
        }

        if (changeRefundText === "Create Refund")
        {
            url = "create-refund-payment/"
        }

        setChangeRefundText("Create/Update")

        HTTP.post(url, data_inputs)
        .then(function (response1) {
            if(response1.status === 200)
            {
                const data1 = response1.data
                let invoice = [data1.new_invoice_number]
                if(invoice[0]){
                    getInvoiceListAPI(invoice, false, true)
                }
            }
        })
        .catch(function (err) {
            console.log(err);
            if (err.response.data.detail)
            {
            alert(err.response.data.detail)
            }
        });
        
    }

    const handleCheckEnableButton = async (invoices, current_row) => {
        let invoice_data = {"invoices": [invoices], "current_row": current_row}
        await HTTP.post('invoice/check_enable_button', invoice_data)
        .then(response => {
            setCheckEnableButton(response.data)
                setPaymentAmount(parseFloat(response.data.em_amount_to_be_paid).toFixed(2))
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    const getCardPayment = (InvoiceSlsId, PaymentType, InvoicePaymentLogID, payment_trans_id=null, PaymentSeqNumber=0, initial) => {
        let url = null
        if(PaymentType==="CASH")
        {
            url = `payment-trans/cash-payment/${InvoicePaymentLogID}/${PaymentType}`
        }
        else
        {
            url = `payment-trans/card-payment/${InvoiceSlsId}/${PaymentType}`
        }
        if (payment_trans_id){
            url += `?payment_trans_id=${payment_trans_id}`;
        }
        HTTP.get(url)
        .then(function (response) {
          if(response.status === 200){

            let data = response.data
            if(Object.keys(data).length === 0)
            {
                data = ''
            }

            if(PaymentType==="CASH")
            {
                setCashPaymentData(data)
                setCardPaymentData('')
                
            }
            else
            {
                setCardPaymentData(data)
                setCashPaymentData('')
            }

            if(getInvoice.short_amt < 0){
                if(!initial){

                    handleCheckEnableButton(getInvoice, PaymentSeqNumber)
                    setCurrentRow(PaymentSeqNumber-1)
                }
                // just for ref
                // if (PaymentType == 'CASH')
                // {
                //     setPaymentAmount(-response.data.AmtAppliedToThisInvoice)
                // }
                // else{
                //     setPaymentAmount(-response.data.APPROVED_AMOUNT)
                // }
                
                // TotalTransAmt
            }


          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    const handleHideShow = () =>{

        if(isHideDiv)
        {
            setIsHideDiv(false)
            setCreateRefund(false)
            // setChangeText("Save Comments")
        }
        else
        {
            setInputs({})
            setIsHideDiv(true)
            setCreateRefund(true)
            // setChangeText("Save")
            setChangeRefundText("Create/Update")
            if(getInvoice)
            {
                setResData([])
                setGetInvoice('')
                setPaymentAmount('')
                localStorage.removeItem("invoice_list");
                setRefInvoiceNumber("")
                setCashPaymentData('')
                setCardPaymentData('')
            }
        }    
        setIsCheckedOtherPayment(prevState => !prevState);            
    }
    
    const handleremovediv = (idx) => {

        setBgColor("#fff")
        if(removeBgColor){
           let rm_id = document.getElementById(`${removeBgColor}`)
            if (rm_id)
            {
             rm_id.style.backgroundColor = '#fff';
             rm_id.style.color = 'black';
            }
        }
        document.getElementById(`${idx}`).style.backgroundColor = '#076EB0';
        document.getElementById(`${idx}`).style.color = '#fff';
        setRemoveBgColor(idx)
        resData.map(data => (
            idx === data.InvoiceNumber ? 
            setRefInvoiceNumber(data.RefInvoiceNumber) 
            : ""
        ))

      };

    const handleShowTrans = (InvoiceSlsId, RandomID, PaymentType, PaymentMedia, InvoicePaymentLogID, payment_trans_id = null, PaymentSeqNumber = 0) => {
        
        setInvoiceTransbgColor("#fff")
        if(removeInvoiceTransbgColor){
           let rm_id = document.getElementById(`${removeInvoiceTransbgColor}`)
           if (rm_id)
           {
            rm_id.style.backgroundColor = '#fff';
            rm_id.style.color = 'black';
           }
        }
        document.getElementById(`${RandomID}`).style.backgroundColor = '#076EB0';
        document.getElementById(`${RandomID}`).style.color = '#fff';
        setRemoveInvoiceTransbgColor(RandomID)
        
        if (PaymentMedia !== "Attachment")   // pending here for call with payment_type checking
        {
            getCardPayment(InvoiceSlsId, PaymentType, InvoicePaymentLogID, payment_trans_id, PaymentSeqNumber, false)
        }
        else
        {
            setCardPaymentData('')
            setCashPaymentData('')
        }

    };

    const handleRemoveInvoice = (event) => {
        if(removeBgColor){

            function isRemove(value) {
                if(value === removeBgColor)
                {
                    document.getElementById(`${value}`).style.backgroundColor = '#fff';
                    document.getElementById(`${value}`).style.color = 'black';
                }
                return value !== removeBgColor;
            }
            
            let multi_invoice_list = localStorage.getItem('invoice_list')
            if(multi_invoice_list)
            {
                multi_invoice_list = multi_invoice_list.split(",")
                multi_invoice_list = multi_invoice_list.filter(isRemove)
            }
            else
            {
                multi_invoice_list = []
            }

            setRemoveBgColor("")
            
            let all_invoice_list = multi_invoice_list

            if(typeof all_invoice_list === 'string'){
                all_invoice_list = [all_invoice_list]
                
            }
            if(all_invoice_list == "" | all_invoice_list === null | all_invoice_list.length === 0){
                window.location.reload();
            }

            getInvoiceListAPI(all_invoice_list, true, false)
        }
        else
        {
            alert("Please select the Invoice Number!")
        }
    };
      
  
    const handleInputChange = (event) => {        
        setInputValue(event.target.value.trim())
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        
        if(inputValue == "" | inputValue === null){
            alert("Please enter the Invoice Number!")
            return false
        }

        let send_all_invoice_list = []
        if (isChecked)
        {
            let multi_invoice_list = localStorage.getItem('invoice_list')

            if (multi_invoice_list)
            {
                multi_invoice_list = multi_invoice_list.split(",")

                if (!multi_invoice_list[0])
                {
                    multi_invoice_list.shift()
                }
            }
            else
            {
                multi_invoice_list = []
            }

            if (!multi_invoice_list.includes(inputValue))
            {
                
                if(multi_invoice_list.length >=6)
                {
                    alert("You can add maximum 6 Invoice only.")
                    return false;
                }
                else
                {
                    multi_invoice_list.push(inputValue);
                    send_all_invoice_list = multi_invoice_list  
                }
            }
            else
            {
                alert("This Invoice Number is in the list already.")
                return false;
            }
        }
        else
        {
            send_all_invoice_list = [inputValue]
            localStorage.setItem('invoice_list', []);
        }

        getInvoiceListAPI(send_all_invoice_list, false, false)
        
    };  

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleAddInvoice = (event) => {
        event.preventDefault();

        if(inputValue == "" | inputValue === null){
            alert("Please enter the Invoice Number!")
            return false
        }
        
        let send_all_invoice_list = []
        let multi_invoice_list = localStorage.getItem('invoice_list')

        if (multi_invoice_list)
        {
            multi_invoice_list = multi_invoice_list.split(",")

            if (!multi_invoice_list[0])
            {
                multi_invoice_list.shift()
            }
        }
        else
        {
            multi_invoice_list = []
        }

        if (!multi_invoice_list.includes(inputValue))
        {
            
            if(multi_invoice_list.length >=6)
            {
                alert("You can add maximum 6 Invoice only.")
                return false;
            }
            else
            {
                multi_invoice_list.push(inputValue);
                send_all_invoice_list = multi_invoice_list  
            }
        }
        else
        {
            alert("This Invoice Number is in the list already.")
            return false;
        }
        
        getInvoiceListAPI(send_all_invoice_list, false, false)

    };  

    const handleChangeInputs = (event) => {

        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))

    }

    const handleSelectType = (event) => {
            event.preventDefault();
            setChangeTypeValue(event.target.value)
            
            if (event.target.value === "V" | event.target.value === "P")
            {
                setDealDisplay("")
                setChangeTypeText("Stock #")
            }
            else if (event.target.value === "C")
            {
                setDealDisplay("none")
                setChangeTypeText("Ck #")
            }
            else
            {
                setDealDisplay("none")
                setChangeTypeText("Ref #")
            }
        };

    const submitCrateOtherPayment = (event) => {

        if (!changeTypeValue == ""){
            
            if(!inputs.amount)
            {
                alert("Please enter Payment Amount!")
                return false
            }
            else if (!(/^-?\d+(\.\d+)?$/.test(inputs.amount)))
            {
                alert("Please enter valid Amount!")
                return false
            }

            if(!inputs.cust_number && changeTypeValue !== "O")
            {
                alert("Please enter or select a valid Customer!")
                return false
            }
            
            HTTP.post(`create-other-payment/generate-invoice/${changeTypeValue}/${inputs.cust_number}?invoice_id=${inputs.invoice_id}`)
            .then(function (response) {
            if(response.status === 200){
                    setInputs(values => ({...values, ['invoice_id']: response.data.invoice_id}))
                    setInputs(values => ({...values, ['type']: changeTypeValue}))
            }
            })
            .catch(function (err) {
                console.log(err);
                if (err.response.data.detail)
                {
                    alert(err.response.data.detail)
                }
            });

        }
        else
        {
            alert("Please select a Payment Type!")
        }


    }

    const handleInvoiceGeneratePDF = async () => {
        await HTTP.get(`invoice-pdf/${getInvoice.invoices[0].InvoiceNumber}`, { responseType: 'blob'})
        .then(response => {
            console.log("-------print invoive data-------")
            const pdfBlob = response.data;
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank')
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    const handleChangePaymentAmount = (event) => {

        if(/^-?\d*\.?\d{0,2}$/.test(event.target.value))
        {   
            setPaymentAmount(event.target.value)
        }
    }

    const [cashPaymentConfirmation, setCashPaymentConfirmation] = useState("None");
    const [cashDueToCustomer, setCashDueToCustomer] = useState("None");
    const [cashReceived, setCashReceived] = useState(null);
    const [hideShowModal, setHideShowModal] = useState(false);
    const [hideShowCardModal, setHideShowCardModal] = useState(false);
    const [paymentWithCredit, setPaymentWithCredit] = useState(false);
    const [paymentAction, setPaymentAction] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");


    const handleCardPayment = (payment_type) => {
        const workstation = localStorage.getItem('workstation');
        if (!workstation) {
            alert('Please select the Workstation first!');
            return
          }
            setHideShowCardModal(true)
            setPaymentMethod(payment_type)
    }

    const handleCashPayment = (val) => {
        if (getInvoice)
        {
            let change_due = Math.abs(parseFloat(cashReceived) - parseFloat(paymentAmount))
            if (!(change_due))
            {
                change_due = null
            }

            let action = paymentAction

            if(val === "first")
            {
                action = ""
            }

            let request_data = {
                "current_row": currentRow,
                "payment_method": "CASH",
                "estimate_not_invoice": "yes",
                "action": action,
                "with_credit": paymentWithCredit,
                "payment_amt": paymentAmount,
                "cash_received": cashReceived,
                "change_due": change_due,
                "invoice_list": [getInvoice]
            }

            HTTP.post("cash-payment/", request_data)
                .then(function (response) {
                if(response.status === 200){
                    const data = response.data
                    
                    setCashReceived(null)

                    if(data.modal === "cash_payment_confirmation")
                    {
                        setCashPaymentConfirmation("")
                        setCashDueToCustomer("None")
                        setHideShowModal(true)
                        setPaymentWithCredit(data.with_credit)
                        setPaymentAction("SALE")
                    }
                    else if(data.modal === "cash_due_to_customer")
                    {
                        setCashPaymentConfirmation("None")
                        setCashDueToCustomer("")
                        setHideShowModal(true)
                        setPaymentWithCredit(data.with_credit)
                        setPaymentAction("REFUND")
                    }
                    else if(data.message)
                    {
                        let multi_invoice_list = localStorage.getItem('invoice_list')
                        multi_invoice_list = multi_invoice_list.split(",")
                        alert(data.message)
                        setHideShowModal(false)
                        getInvoiceListAPI(multi_invoice_list, false, false)
                        console.log("invoice payment successfull")

                        setPaymentWithCredit(false)
                        setPaymentAction("")
                    }
                }
                })
                .catch(function (err) {
                    console.log(err);
                    if (err.response.data.detail)
                    {
                        alert(err.response.data.detail)
                    }
                });
        }

    }
    const isButtonEnabled = () => {
        if (changeRefundText === 'Create/Update'){
            return 'invoice_id' in inputs && resData.length==0;
        }
        else return true
      };
  
    return ( 
      <MyContext.Provider value={getInvoiceListAPI}>

      {/* <button onClick={handle.enter}>
        Enter fullscreen
      </button> */}

        <FullScreen handle={handle}>
        
        <Header />
        <div className="page-content">
            <div className="container-fluid">

            <div className="row mt-0">
                <div className="col-lg-12 col-md-12 mb-0">


                <div className="top-filter">
                            <div className="invoice-input">
                                <input type="text" placeholder={t('Enter Invoice')} value={inputValue} onChange={handleInputChange} className="form-control invoiceno-txt"/>
                                <button type="submit" className="btn btn-primary getinvoice-btn m-1" onClick={handleSubmit}>{t("Get Invoice")}</button>
                            </div>
                            <div className="invoice-btns">
                                <div className="d-inline-block me-0">
                                    <input type="checkbox" className='m-1' id="add_invoice" checked={isChecked} onChange={handleCheckboxChange}/>
                                    <label htmlFor="add_invoice">{t("Add")}</label>
                                </div>
                                <button type="submit" className="btn btn-primary m-1" onClick={handleAddInvoice} >{t("Add Invoice")}</button>
                                <button type="submit" className="btn btn-primary m-1" onClick={handleRemoveInvoice}>{t("Remove Invoice")}</button>
                                <input type={getInvoice ? "button" : "hidden"} className="btn btn-primary m-1" onClick={handleInvoiceGeneratePDF} value={t("Proof of Payment")} />
                            </div>
                        </div>

                </div>
            </div>


                <div className="row mt-0">
                    <div className="col-lg-5 col-md-12 mb-3">

                        <div className="mt-3">
                            <div className="invoice-table primary-table table-responsive-sm">
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th className="text-center">{t("Invoice")} #</th>
                                            <th className="price-align">{t("Invoice Amt")}</th>
                                            <th className="price-align">{t("Paid Amt")}</th>
                                            <th className="price-align">{t("Balance Due")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                     {
                                     resData?
                                     resData.map(data => (
                                        <tr key={data.InvoiceNumber} className='removeColor' style={{backgroundColor: bgColor}} id={data.InvoiceNumber} onClick={()=>handleremovediv(data.InvoiceNumber)} >
                                            <td  className="text-center">{data.InvoiceNumber}</td>
                                            <td  className="price-align">{data.TotalPriceAmt ? t('$') + parseFloat(data.TotalPriceAmt).toFixed(2) : t('$')+"0.00"}</td>
                                            <td  className="price-align">{data.TotalPaidAmt ? t('$') + parseFloat(data.TotalPaidAmt).toFixed(2) : t('$')+"0.00"}</td>
                                            <td  className="price-align">{data.BalanceDueAmt ? t('$') + parseFloat(data.BalanceDueAmt).toFixed(2) : t('$')+"0.00"}</td>
                                        </tr>
                                      ))  
                                      : ""
                                    }
                                    
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-2 ">
                                {
                                getInvoice?
                                    <div className="row total-row mx-1" style={{borderRadius:"5px", border:"0px solid black", padding:"5px", boxShadow:"0px 2px 0px 2px rgb(216, 226, 226)"}}>
                                        <div className='col text-center'>{t("Total")} : </div>
                                        <div className='col price-align'>{getInvoice.total_price_amt ? t('$') + parseFloat(getInvoice.total_price_amt).toFixed(2) : t("$")+"0.00"}</div>
                                        <div className='col price-align'>{getInvoice.paid_visa_amt ? t('$') + parseFloat(getInvoice.paid_visa_amt).toFixed(2) : t("$")+"0.00"}</div>
                                        <div className='col price-align'>{getInvoice.short_amt ? t('$') + parseFloat(getInvoice.short_amt).toFixed(2) : t("$")+"0.00"}</div>
                                    </div>
                                :""
                                }
                            </div>
                            <div className="filter-result">
                                <div className="row mt-0 payment-due">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor="PaymentAmount" className="label-amount">{t("Payment Amount")} {t("$")}: </label>
                                            </div>
                                            <div className="col">
                                                <input type="text" className="form-control price-align" onChange={handleChangePaymentAmount} placeholder="0.00" value={paymentAmount}/>
                                            </div>
                                        </div> 

                                        <div className="row mt-3">
                                            <div className="col">
                                                <label htmlFor="amountPaid" className="label-amount">{t("Amount Paid")} {t("$")}: </label>
                                            </div>
                                            <div className="col">
                                                <input type="text" className="form-control price-align" placeholder="0.00" value={getInvoice.paid_visa_amt ? parseFloat(getInvoice.paid_visa_amt).toFixed(2) : ""} readOnly/>
                                            </div>
                                        </div> 
                                    </div>
                                    <div className="col-md-4 payment-mode">
                                        <button type="submit" onClick={()=>handleCashPayment("first")} className="btn btn-primary" disabled={ !checkEnableButton.cb_cash }>{t("Cash")}</button> 
                                        <button type="submit" onClick={()=>handleCardPayment("DEBIT")} className="btn btn-primary mt-1" disabled={ !checkEnableButton.cb_debit }>{t("Debit")}</button>
                                        <button type="submit" onClick={()=>handleCardPayment("CREDIT")} className="btn btn-primary mt-1" disabled={ !checkEnableButton.cb_credit }>{t("Credit")}</button>
                                    
                                        {/* <button type="submit" onClick={()=>handleCashPayment("first")} className="btn btn-primary" disabled={ parseFloat(getInvoice.short_amt) === 0 | (getInvoice.short_amt < 0 && getInvoice.cb_cash === false)}>{t("Cash")}</button>  */}
                                        {/* <button type="submit" onClick={()=>handleCardPayment("DEBIT")} className="btn btn-primary mt-1" disabled={ parseFloat(getInvoice.short_amt) === 0 }>{t("Debit")}</button> */}
                                        {/* <button type="submit" onClick={()=>handleCardPayment("CREDIT")} className="btn btn-primary mt-1" disabled={ parseFloat(getInvoice.short_amt) === 0 }>{t("Credit")}</button> */}
                                    
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                    <input type="text" id="ref_invoice" placeholder={t('Ref. Invoice Number')} className="form-control mt-2" value={refInvoiceNumber ? "Ref Invoice : " + refInvoiceNumber : ""} readOnly />  
                                    </div>
                                </div>

                                <div className="mt-1">
                                    <div className="row mt-0 payment-due">
                                        <div className="col">
                                            <label htmlFor="label-amount" className="label-amount" ><h5>{t("BALANCE")} {t('$')}: </h5></label>
                                        </div>
                                        <div className="col-8">
                                            <input type="text" className="form-control price-align" placeholder="0.00" value={getInvoice.short_amt ? parseFloat(getInvoice.short_amt).toFixed(2) : "0.00"} readOnly/>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                    
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <div className="other-payment-mode section-boxes">
                                    <div style={{textAlign:"center"}}>
                                        <button type="submit" className="btn btn-tertiary m-1" style={{padding:'4px'}}>
                                            <input type="checkbox" id="chk_other_payment" name="chk_other_payment" checked={isCheckedOtherPayment} className='m-1' onClick={() => handleHideShow()}/>
                                            <label htmlFor="chk_other_payment" >{t("Create Other Payment")}</label>
                                        </button>
                                        { 
                                        getInvoice && getInvoice.e_signature_display_text ? ""
                                        :   
                                        createRefund && <button type="submit" style={{padding:'4px'}} disabled={!isButtonEnabled()} className="m-1 btn btn-tertiary" onClick={() => handleCreateRefund()}>{t(changeRefundText)}</button>
                                         
                                    }

                                    </div>
                                    {
                                        getInvoice && getInvoice.e_signature_display_text 
                                        ?
                                            <div className='payment-form mt-2' style={{height:"395px", overflowY:"auto"}}>
                                            {
                                            getInvoice.e_signature_display_text.split("\r\n").map((data, index)=> (
                                                <p className='m-2'>
                                                    {data}
                                                </p>
                                            ))
                                            }
                                            </div>
                                        :
                                            isHideDiv && createRefund ? 
                                            <CreateOtherPayment 
                                            key = {1}
                                            id = {1}
                                            getInvoice={getInvoice}
                                            refInvoiceNumber={refInvoiceNumber}
                                            changeTypeText={changeTypeText}
                                            handleSelectType={handleSelectType}
                                            inputs={inputs}
                                            setInputs={setInputs}
                                            handleChangeInputs={handleChangeInputs}
                                            dealDisplay={dealDisplay}
                                            />

                                            : ""
                                    }

                                    {   
                                    getInvoice && getInvoice.e_signature_display_text ?
                                        ""
                                    :
                                        isHideDiv && createRefund ? 
                                            <div className="text-center mt-3">
                                                    <button type="submit" className="btn btn-primary save-payment" disabled={resData.length !== 0} onClick={submitCrateOtherPayment}>{t(changeText)}</button>
                                            </div>
                                        : ""
                                    }

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="section-boxes" style={{height:"486px", overflowY:"auto"}}>
                                <table className="table table-borderless" >

                                {
                                    
                                    !cardPaymentData == ''? 
                                    <CardPaymentsTran 
                                    key = {3}
                                    id = {3}
                                    cardPaymentData={cardPaymentData}
                                    />
                                    : 
                                    !cashPaymentData == ''? 
                                    <CashPaymentsTran 
                                    key = {2}
                                    id = {2}
                                    cashPaymentData={cashPaymentData}
                                    />
                                    :""

                                }

                                </table>

                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            
                            <div className="col">
                                <div className="input-group">
                                    <span className="input-group-text w-25 text-wrap">
                                        { 
                                            getInvoice && getInvoice.e_signature_display_text ?
                                                (getInvoice.invoices[0].CustomerId ? getInvoice.invoices[0].CustomerId + ' - Bill to: ' : "") + 
                                                (getInvoice.invoices[0].CustomerBillToName ? getInvoice.invoices[0].CustomerBillToName.trim() : "")
                                            : ""
                                        }
                                    </span>
                                    <textarea className="form-control p-2" rows="3" aria-label="With textarea" value={getInvoice && getInvoice.invoices[0].Notes? getInvoice.invoices[0].Notes : "" } readOnly></textarea>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="invoice-payment-table mt-3 bg-white primary-table table-responsive-sm" style={{width:"100%", overflowX:"auto", boxShadow: "3px 3px 1px rgb(216, 226, 226)"}}>
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th className="text-center">{t('Invoice')} #</th>
                                <th className="text-end">{t("Seq")} #</th>
                                <th className="text-end">{t("Pymt Type")}</th>
                                <th className="text-end">{t("Pymt Media")}</th>
                                <th className="text-end">{t("Reference")} #</th>
                                <th className="text-end">{t("Total Trans Amt")}</th>
                                <th className="text-end">{t("Amt Applied To")}</th>

                                <th className="text-end">{t("Cash Received")}</th>
                                <th className="text-end">{t("Change Due")}</th>
                                <th className="text-end">{t("Payment Processed By")}</th>
                                <th className="text-end">{t("Payment Date")}</th>
                                <th className="text-end">{t("Payment Modified By")}</th>
                                <th className="text-end">{t("Payment Modified Date")}</th>
                                <th className="text-end">{t("Customer Number")}</th>
                                <th className="text-end">{t("Journal Entry ID")}</th>
                                <th className="text-end">{t("POS Trans ID")}</th>
                                <th className="text-end">{t("Tax Amt")}</th>
                                <th className="text-end">{t("GL Code")}</th>
                                <th className="text-end">{t("Processor")}</th>
                                <th className="text-end"># {t("of Invoice")}</th>
                                <th className="text-end">{t("List Invoices")}</th>
                                <th className="text-end">{t("Surchargeamt")}</th>
                                <th className="text-end">{t("Surchargepct")}</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                        {   
                            resData?
                            resData.map((data, index)=> (

                                data.invoice_trans ?
                                data.invoice_trans.map((transData, index1) => (
                                
                                <tr key={transData.InvoiceTransXrefID} style={{backgroundColor: invoiceTransbgColor}} id={index1 + "786" + index} onClick={()=>handleShowTrans(transData.InvoiceNumber, index1 + "786" + index, transData.PaymentType, transData.PaymentMedia, transData.InvoicePaymentLogID, transData.PaymentTransID, transData.indexNumber)}>
                                    <td className="text-center">{transData.InvoiceNumber}</td>
                                    <td className="text-end">{transData.PaymentSeqNumber}</td>
                                    <td className="text-end">{transData.PaymentType}</td>
                                    <td className="text-end">{transData.PaymentMedia}</td>
                                    <td className="text-end">{transData.ReferenceNumber}</td>
                                    <td className="text-end">{transData.TotalTransAmt ? t('$') + parseFloat(transData.TotalTransAmt).toFixed(2) : ""}</td>
                                    <td className="text-end">{transData.AmtAppliedToThisInvoice ? t('$') + parseFloat(transData.AmtAppliedToThisInvoice).toFixed(2) : ""}</td>

                                    <td className="text-end">{transData.CashReceived ? t('$') + parseFloat(transData.CashReceived).toFixed(2) : ""}</td>
                                    <td className="text-end">{transData.ChangeDue ? t('$') + parseFloat(transData.ChangeDue).toFixed(2) : ""}</td>
                                    <td className="text-end">{transData.EnteredByUserId}</td>
                                    <td className="text-end">{moment(new Date(transData.EnteredDate)).format(dateFormat.toUpperCase() + ' hh:mm A')}</td>
                                    <td className="text-end">{transData.ModifiedByUserId}</td>
                                    <td className="text-end">{moment(new Date(transData.ModifiedDate)).format(dateFormat.toUpperCase() + ' hh:mm A')}</td>
                                    <td className="text-end">{transData.CustomerNumber}</td>
                                    <td className="text-end">{transData.JournalEntryID}</td>
                                    <td className="text-end">{transData.PaymentTransID}</td>
                                    <td className="text-end">{transData.TaxAmt ? t('$') + parseFloat(transData.TaxAmt).toFixed(2) : ""}</td>
                                    <td className="text-end">{transData.GLCode}</td>
                                    <td className="text-end">{transData.Processor}</td>
                                    <td className="text-end">{transData.NumOfInvoiceThisTransApplied}</td>
                                    <td className="text-end">{transData.ListOfInvoiceThisTransApplied}</td>
                                    <td className="text-end">{transData.SurchargeAmt ? t('$') + parseFloat(transData.SurchargeAmt).toFixed(2) : ""}</td>
                                    <td className="text-end">{transData.SurchargePct ? t('$') + parseFloat(transData.SurchargePct).toFixed(4) : ""}</td>
                                </tr>

                                ))
                                : ""
                            ))  
                            : ""
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <Footer /> 
        
        {
            hideShowModal ? 
            <CashPayment 
            key = {4}
            id = {4}
            handleCashPayment = {handleCashPayment}
            paymentAmount = {paymentAmount}
            cashPaymentConfirmation = {cashPaymentConfirmation}
            cashDueToCustomer = {cashDueToCustomer}
            cashReceived = {cashReceived}
            setCashReceived = {setCashReceived}
            hideShowModal = {hideShowModal}
            setHideShowModal = {setHideShowModal}
            currentRow = {currentRow}
            />
            : ""
        }

        {
        hideShowCardModal ?
        <CardPayment
        key = {5}
        id = {5}
        hideShowCardModal = {hideShowCardModal}
        setHideShowCardModal = {setHideShowCardModal}
        getInvoice = {getInvoice}
        paymentAmount = {paymentAmount}
        setPaymentAmount = {setPaymentAmount}
        paymentMethod = {paymentMethod}
        getInvoiceListAPI = {getInvoiceListAPI}
        setGetInvoice = {setGetInvoice}
        currentRow = {currentRow}
        />
        : ""
        }

        </FullScreen>
        </MyContext.Provider>
    );
  
};

export default Dashboard;
