import { useState, useEffect } from 'react'
import { HTTP } from '../../api/http-common';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";


function LinklyPairing({handleShowPairModel}) {
    const [t, i18n] = useTranslation("global");
    const [pairCode, setPairCode] = useState();
    const [terminalId, setTerminalId] = useState();
    const [successMessage, setSuccessMessage] = useState()
    const [workstation, setWorkstation] = useState("")

    const handleClose = () => handleShowPairModel();

    const handleInputChange = (event) => {
        event.preventDefault();
        setPairCode(event.target.value)
        console.log(event.target.value)
    }

    const handleWorkstationName = (e) =>{
        setWorkstation(e.target.value)
        console.log("e.target.value", e.target.value)
    }

    const handleTerminalIdChange = (event) => {
        event.preventDefault();
        setTerminalId(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("input value is " + pairCode)

        if (!pairCode) {
            alert("Pair code is empty")
            return
        }
        if (!workstation) {
            alert("Workstation name is empty")
            return
        }

        const url = `linkly_payment_gateway/linkly_generate_secret_key`
        let pairing_data = {"pair_code": pairCode, "workstation_name": workstation}
        HTTP.post(url, pairing_data)
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data

                    alert(data.detail)

                }
            })
            .catch(function (err) {
                console.log("Error ", err)
                alert(err.response.data.detail)
                
            });

    }


    const [alertShow, setAlertShow] = useState(false);
    const alertHandleClose = () => setAlertShow(false);

    const handleTerminalIdSubmit = (already) => {
        // event.preventDefault();
        
        if(already)
        {
            setAlertShow(false);
        }

        if (terminalId == '' || terminalId == null) {
            alert("Terminal Id is empty")
            setSuccessMessage("Terminal Id is empty")
            return
        }
        if (!workstation) {
            alert("Workstation name is empty")
            return
        }

        const url = `linkly_payment_gateway/connect_to_terminal/?terminal_id=${terminalId}&action=${already}&workstation_name=${workstation}`

        HTTP.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    const data = response.data
                    console.log("data is ", data)

                    setAlertShow(data?.action);

                    if(data?.action)
                    {
                        setSuccessMessage(data?.detail)
                    }
                    else
                    {
                        alert(data?.detail)
                    }
                }
            })
            .catch(function (err) {
                console.log("error " + err)
                alert(err.response.data.detail)
            })
    }

    return (
        <>

        <Modal
            show={true}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("Linkly Pairing")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
            

                <div className="row align-items-center text-center mb-3">
                    <div className="col-sm-4">
                        <label className="form-label">{t("Workstation")} *:</label>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" required className="form-control" onChange={handleWorkstationName} placeholder="Workstation Name" name="workstation"/>
                    </div>
                </div>
                <hr className="border-dark mb-3"/>
                <div className="row align-items-center text-center mt-2">
                    <div className="col-sm-4">
                        <label className="form-label">{t("Linkly Pair Code")}:</label>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" required className="form-control" name="term_id" placeholder={t('Pair Code')} onChange={handleInputChange} value={pairCode}/>
                    </div>
                </div>

                <div className="row align-items-center text-center ">
                    <div className="col-sm mt-3">
                        <Button variant="primary m-2"  onClick={handleSubmit}>{t("Pair")}</Button>
                        <Button variant="primary m-2" onClick={handleClose}>{t("Cancel")}</Button>
                    </div>
                </div>

                <hr className="border-dark mb-3"/>

                <p className='mb-3'>{t("Connect workstation to Terminal")}</p>

                <div className="row align-items-center text-center mt-2">
                    <div className="col-sm-4">
                        <label className="form-label">{t("Terminal ID")}:</label>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" className="form-control" name="term_id" placeholder={t('Terminal ID')} onChange={handleTerminalIdChange} value={terminalId}/>
                    </div>
                </div>

                <div className="row align-items-center text-center ">
                    <div className="col-sm mt-3">
                        <Button variant="primary m-2"  onClick={()=>handleTerminalIdSubmit(false)}>{t("Connect")}</Button>
                        <Button variant="primary m-2" onClick={handleClose}>{t("Cancel")}</Button>
                    </div>
                </div>

            </Modal.Body>
        </Modal>


        <Modal show={alertShow} onHide={alertHandleClose} animation={false} size='sm' centered>
            <Modal.Body>
                {successMessage}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={()=>handleTerminalIdSubmit(true)} size='sm'>
                Yes
            </Button>
            <Button variant="secondary" onClick={alertHandleClose} size='sm'>
                No
            </Button>
            </Modal.Footer>
        </Modal>

        </>
    )


}

export default LinklyPairing

