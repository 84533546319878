import React, { useState, useEffect, useRef } from "react";
import { HTTP } from "../../api/http-common";
import Header from "../partials/Header";
import '../../CSS/InvoiceListReports.css'
import { Outlet, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DatePicker } from 'rsuite';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import CustomPagination from "./CustomPagination";
import PageSize from "./PageSize";
import Footer from "../partials/Footer";


export default function InvoiceListReports(props) {
    const [t, i18n] = useTranslation("global");

    const [selectedBranch, setSelectedBranch] = useState("");
    const [updateBranch, setUpdateBranch] = useState("");
    const [branchData, setBranchData] = useState([]);

    const [users, setUsers] = useState([]);
    const [invoiceType, setInvoiceType] = useState([]);
    const [invoiceDate, setInvoiceDate] = useState(new Date());

    const [invoiceListAll, setInvoiceListAll] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [invoiceTransData, setInvoiceTransData] = useState([]);


    const [totalPaidCashAmt, setTotalPaidCashAmt] = useState(0.00);
    const [totalDue, setTotalDue] = useState(0.00);
    const [totalPaidCreditCard, setTotalPaidCreditCard] = useState(0.00);
    const [totalPaidOrbitalAmt, setTotalPaidOrbitalAmt] = useState(0.00);
    const [totalPaidDebitAmt, setTotalPaidDebitAmt] = useState(0.00);
    const [totalPaidCheckAmt, setTotalPaidCheckAmt] = useState(0.00);
    const [totalPaidACH, setTotalPaidACH] = useState(0.00);
    const [totalOverAmt, setTotalOverAmt] = useState(0.00);
    const [totalShortAmt, setTotalShortAmt] = useState(0.00);
    const [removeBgColor, setRemoveBgColor] = useState('');
    const [userIDBgColor, setUserIDBgColor] = useState('');

    const [displayBranchSelectionFlag, setDisplayBranchSelectionFlag] = useState(false);

    const [userFilterVal, setUserFilterVal] = useState("All");
    const [filterUserID, setFilterUserID] = useState(0);  // this is use in print pdf request data
    const [invoiceTypeFilterVal, setInvoiceTypeFilterVal] = useState("AINV");

    const [searchInputVal, setSearchInputVal] = useState("");
    const [searchRadioVal, setSearchRadioVal] = useState("invoice");
    const [searchIndexVal, setSearchIndexVal] = useState(0);
    const [fromDateInvoice, setFromDateInvoice] = useState(0);


    const tableRef = useRef(null);

    const [show, setShow] = useState(false);
    const [pageSize, setPageSize] = useState(10);

    let count = 0
    
    let dateFormat = process.env.REACT_APP_DATE_FORMAT;
    if(!(dateFormat)){
        dateFormat = 'MM/dd/yyyy'
    }

    const handleInvoiceTypeAndUsers = async (branchName) =>
    {
        let currentDate = new Date();
        let currentDateVal = currentDate.toISOString().split('T')[0];

        // console.log(updateBranch.split('- ')[1] + " "+ branchName)
        let requestData = {
            "branch_name": branchName.split('- ')[1],
            "invoice_date": currentDateVal
          }

        await HTTP.post('invoice_list_reports/combine_branch_name_ddw_cashier', requestData)
        .then(response => {
            setUsers(response.data.user_details);
            setInvoiceType(response.data.invoice_type);
            setFromDateInvoice(currentDateVal)
            handleGetInvoiceList(currentDateVal)
            setInvoiceDate(currentDate);

        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    useEffect(() => {
        if(count === 0){
            // this is not in use
            // HTTP.get('invoice_list_reports/branch_selection_flag')
            // .then(response => {
            //     console.log(response.data.flag)
            //     setDisplayBranchSelectionFlag(response.data.flag);
            // })
            // .catch(error => {
            //   console.error('Error:', error);
            // });

            let user_info = localStorage.getItem("user_info")
            user_info = JSON.parse(user_info);

            let user_id_branch = user_info.UserId + " - " + user_info.BranchAccountName
            // console.log("user_id with branch_name " + user_id_branch)
            setSelectedBranch(user_id_branch);
            setUpdateBranch(user_id_branch);
            setDisplayBranchSelectionFlag(user_info.DisplayBranchSelectionFlag);

            let currentDate = new Date();
            setInvoiceDate(currentDate);
            handleInvoiceTypeAndUsers(user_id_branch);
        }
        count = 1
      }, [1]);

    const handleClose = () => {
        setSelectedBranch(updateBranch);
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);

        HTTP.get('invoice_list_reports/invoice_payment_branch')
        .then(response => {
            setBranchData(response.data);

        })
        .catch(error => {
          console.error('Error:', error);
        });
        
    }
    
    const handleChangeBranch = (branchComb) =>
    {
        setSelectedBranch(branchComb);
    }

    const handleSetBranch = () => 
    {

        const checkboxes = document.querySelectorAll('.BranchComb-CheckBox');
        let branch_val = ""
        checkboxes.forEach((checkbox) => {
          if (checkbox.checked) {
            branch_val = checkbox.value
          }
        });
        
        setUpdateBranch(branch_val);
        setShow(false);
        handleInvoiceTypeAndUsers(branch_val);

    }

    const handleInvoiceTrans = (invoice_number, invoice_payment_log_id, journal_entry_id) => {
        let requestData = {
            "invoice_number": invoice_number,
            "invoice_payment_log_id": invoice_payment_log_id,
            "journal_entry_id": journal_entry_id
          }
        
          console.log(invoice_number + " " + invoice_payment_log_id + " " + journal_entry_id)
        HTTP.post('invoice_list_reports/invoice_trans',requestData)
        .then(response => {
            console.log(response.data + " trans data")
            setInvoiceTransData(response.data)

        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    const handleGetInvoiceList = async (date, pageNum=1, pagesize=10) =>
    {
        if(removeBgColor){
            let rm_id = document.getElementById(`${removeBgColor}`)
             if (rm_id)
             {
              rm_id.style.backgroundColor = '#fff';
              rm_id.style.color = 'black';
             }
         }

        let  branch_account_name = ""
        if(updateBranch) 
        {
            branch_account_name = updateBranch.split('- ')[1] 
        }
        else
        {
            let user_info = localStorage.getItem("user_info")
            user_info = JSON.parse(user_info);
            branch_account_name = user_info.BranchAccountName 
        }

        console.log("branch_account_name " + branch_account_name)

         
        let requestData = {
            "invoice_number": "",
            "order_date": date,
            "invoice_payment_log_id": 0,
            "branch_account_name": branch_account_name,
            "page_number": pageNum,
            "page_size": pagesize
          }
        await HTTP.post('invoice_list_reports',requestData)
        .then(response => {
            console.log(response.data)
            setTotalDue(0)
            setTotalPaidCashAmt(0)
            setTotalPaidCreditCard(0)
            setTotalPaidOrbitalAmt(0)
            setTotalPaidDebitAmt(0)
            setTotalPaidCheckAmt(0)
            setTotalPaidACH(0)
            setTotalOverAmt(0)
            setTotalShortAmt(0)
            setInvoiceList(response.data);
            setInvoiceListAll(response.data)

            response.data.map((item, index) => {
                if(index === 0)
                {
                    setInvoiceData(item)
                    handleInvoiceTrans(item.InvoiceNumber, item.InvoicePaymentLogID, item.JournalEntryId)
                }

                const CalPaidCreditCardAmt =
                    (item.PaidVisaAmt !== null ? parseFloat(item.PaidVisaAmt) : 0.00) +
                    (item.PaidMasterAmt !== null ? parseFloat(item.PaidMasterAmt) : 0.00) +
                    (item.PaidAmexAmt !== null ? parseFloat(item.PaidAmexAmt) : 0.00) +
                    (item.PaidComDataAmt !== null ? parseFloat(item.PaidComDataAmt) : 0.00) +
                    (item.PaidDiscoverAmt !== null ? parseFloat(item.PaidDiscoverAmt) : 0.00);
                item.PaidCreditCard = CalPaidCreditCardAmt
                
                if(item.TotalPriceAmt && !item.ChildInvoicePaymentFlag){setTotalDue(prevTotalDue => prevTotalDue + parseFloat(item.TotalPriceAmt))}
                if(item.PaidCashAmt){setTotalPaidCashAmt(prevTotalPaidCashAmt => prevTotalPaidCashAmt + parseFloat(item.PaidCashAmt))}
                if(item.PaidCreditCard){setTotalPaidCreditCard(prevTotalPaidCreditCard => prevTotalPaidCreditCard + parseFloat(item.PaidCreditCard))}
                if(item.PaidOrbitalAmt){setTotalPaidOrbitalAmt(prevTotalPaidOrbitalAmt => prevTotalPaidOrbitalAmt + parseFloat(item.PaidOrbitalAmt))}
                if(item.PaidDebitAmt){setTotalPaidDebitAmt(prevTotalPaidDebitAmt => prevTotalPaidDebitAmt + parseFloat(item.PaidDebitAmt))}
                if(item.PaidCheckAmt){setTotalPaidCheckAmt(prevTotalPaidCheckAmt => prevTotalPaidCheckAmt + parseFloat(item.PaidCheckAmt))}
                if(item.PaidACH){setTotalPaidACH(prevTotalPaidACH => prevTotalPaidACH + parseFloat(item.PaidACH))}
                if(item.OverAmt){setTotalOverAmt(prevTotalOverAmt => prevTotalOverAmt + parseFloat(item.OverAmt))}
                if(item.ShortAmt){setTotalShortAmt(prevShortAmt => prevShortAmt + parseFloat(item.ShortAmt))}

            })
            
        })
        .catch(error => {
          if (error.response.status === 404)  
          {
            console.log("data not found")
            setInvoiceList([]);
            setInvoiceListAll([])
            setInvoiceData([])
            setInvoiceTransData([])
          }
          console.error('Error:', error);
        });
        
    }

    const handleDateRange = (range) => {
        console.log(range)
        if(range){
            let dateObject = new Date(range);
            let year = dateObject.getFullYear();
            let month = dateObject.getMonth() + 1; // Months are zero-indexed, so add 1
            let day = dateObject.getDate();
            // Format the date
            let fromDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            setFromDateInvoice(fromDate)
            console.log(fromDate + " from date");
            setInvoiceDate(range)
            handleGetInvoiceList(fromDate)

            setUserFilterVal("All")
            setInvoiceTypeFilterVal("AINV")
            setSearchRadioVal("invoice");
        }
        else{
            setInvoiceDate(range)
            handleReset()
        }
    }

    const handleChangeUsers = (event) =>{
        event.preventDefault();
        console.log(event.target.value.split(',')[0])
        console.log(event.target.value.split(',')[2])
        setUserFilterVal(event.target.value.split(',')[0])  
        setFilterUserID(event.target.value.split(',')[2])

        setInvoiceTypeFilterVal("AINV")

        if(event.target.value.split(',')[0] === "All")
        {
            setInvoiceList(invoiceListAll);
            setInvoiceData(invoiceListAll[0])
        }
        else
        {
            let filterVal = []    
            setInvoiceData([])

            invoiceListAll.forEach((item, index) => {
                console.log(item)
                if(item.SalesPersonId == event.target.value.split(',')[0] && 
                    item.PaymentEnteredByUserId == event.target.value.split(',')[2])
                {
                    filterVal.push(item)
                }
            });

            setInvoiceList(filterVal);
            setInvoiceData(filterVal[0] ? filterVal[0] : [])
        }

        if(removeBgColor){
            let rm_id = document.getElementById(`${removeBgColor}`)
             if (rm_id)
             {
              rm_id.style.backgroundColor = '#fff';
              rm_id.style.color = 'black';
             }
         }
    }

    const handleChangeInvoiceType = (event) =>{
        event.preventDefault();
        console.log(event.target.value)
        setInvoiceTypeFilterVal(event.target.value.split(',')[0])

        setUserFilterVal("All")

        if(event.target.value.split(',')[0] === "AINV")
        {
            setInvoiceList(invoiceListAll);
            setInvoiceData(invoiceListAll[0])
        }
        else
        {
            let filterVal = []    
            setInvoiceData([])
            invoiceListAll.forEach((item, index) => {

                console.log(item.InvoiceNumber.substr(0, 1) + "  " + event.target.value.split(',')[1])
                console.log(item.InvoiceNumber.substr(0, 3) + "  POS")
                console.log(item.OrderType +"  " + event.target.value.split(',')[0])
                if(
                    item.InvoiceNumber.substr(0, 1) == event.target.value.split(',')[1] |
                    (item.InvoiceNumber.substr(0, 3) == "POS" &&
                    item.OrderType == event.target.value.split(',')[0]))
                {
                    filterVal.push(item)

                }
            });

            setInvoiceList(filterVal);
            setInvoiceData(filterVal[0] ? filterVal[0] : [])
        }

        if(removeBgColor){
            let rm_id = document.getElementById(`${removeBgColor}`)
             if (rm_id)
             {
              rm_id.style.backgroundColor = '#fff';
              rm_id.style.color = 'black';
             }
         }
    }


    const handleremovediv = (index, invoice_number, fromRow, userID) => {

        if(fromRow){
            setSearchIndexVal(index + 1)
        }

        if(removeBgColor){
           let rm_id = document.getElementById(`${removeBgColor}`)
           if (rm_id)
           {
                if (userIDBgColor)
                {
                    rm_id.style.backgroundColor = '#fff';
                    rm_id.style.color = 'black';
                }
                else
                {
                    rm_id.style.backgroundColor = '#ffff00';
                    rm_id.style.color = 'black';
                }
            }
        }
        
        document.getElementById(`${invoice_number}`).style.backgroundColor = '#076EB0';
        document.getElementById(`${invoice_number}`).style.color = '#fff';
        setRemoveBgColor(invoice_number)
        setUserIDBgColor(userID)
        
        setInvoiceData(invoiceList[index])
        handleInvoiceTrans(invoice_number, invoiceList[index].InvoicePaymentLogID, invoiceList[index].JournalEntryId)

       
      };

    
    const handleSearchInputVal = (event) => {
        event.preventDefault();
        console.log(event.target.value)
        setSearchInputVal(event.target.value)
    }

    const handleSearchRadioVal = (value) => {
        console.log(value)
        setSearchRadioVal(value)
    }

    const searchCommanCode = (i) => 
    {
        if(i + 1 === invoiceListAll.length)
        {
            setSearchIndexVal(0)
        }
        else
        {
            setSearchIndexVal(i + 1)
        }

        handleremovediv(i, invoiceListAll[i].InvoiceNumber, false, 'nafees')

        const targetRow = document.getElementById(invoiceListAll[i].InvoiceNumber);
        if(targetRow) {
            targetRow.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
            });
        }
    }

    const handleSearch = () => {
        if(searchInputVal)
        {
            if(searchRadioVal === "invoice")
            {
                let searchFound = false
                for (let i = searchIndexVal; i < invoiceListAll.length; i++) {
                    if(invoiceListAll[i].InvoiceNumber.includes(searchInputVal.toUpperCase()))
                    {
                        if(removeBgColor !== invoiceListAll[i].InvoiceNumber)
                        {
                            searchFound = true
                        }
                        searchCommanCode(i)
                        break;
                    }
                }
                if(!(searchFound) && searchIndexVal > 0)
                {
                    for (let i = 0; i < searchIndexVal; i++) {
    
                        if(invoiceListAll[i].InvoiceNumber.includes(searchInputVal.toUpperCase()))
                        {
                            if(removeBgColor !== invoiceListAll[i].InvoiceNumber)
                            {
                                searchFound = true
                            }
                            searchCommanCode(i)
                            break;
                        }
                    }
                }

                if(!(searchFound))
                {
                    alert("The search reached end of rows.");
                    return
                }
            }
            else
            {
                let searchFound = false
                for (let i = searchIndexVal; i < invoiceListAll.length; i++) {

                    if
                    (
                        parseFloat(invoiceListAll[i].TotalPriceAmt) === parseFloat(searchInputVal) | 
                        parseFloat(invoiceListAll[i].PaidCashAmt) === parseFloat(searchInputVal) | 
                        parseFloat(invoiceListAll[i].PaidCreditCard) === parseFloat(searchInputVal) | 
                        parseFloat(invoiceListAll[i].PaidOrbitalAmt) === parseFloat(searchInputVal) | 
                        parseFloat(invoiceListAll[i].PaidDebitAmt) === parseFloat(searchInputVal) | 
                        parseFloat(invoiceListAll[i].PaidCheckAmt) === parseFloat(searchInputVal) 
                    )
                    {
                        if(removeBgColor !== invoiceListAll[i].InvoiceNumber)
                        {
                            searchFound = true
                        }
                        searchCommanCode(i)
                        break;
                    }
                }
                if(!(searchFound) && searchIndexVal > 0)
                {
                    for (let i = 0; i < searchIndexVal; i++) {
    
                        if
                        (
                            parseFloat(invoiceListAll[i].TotalPriceAmt) === parseFloat(searchInputVal) | 
                            parseFloat(invoiceListAll[i].PaidCashAmt) === parseFloat(searchInputVal) | 
                            parseFloat(invoiceListAll[i].PaidCreditCard) === parseFloat(searchInputVal) | 
                            parseFloat(invoiceListAll[i].PaidOrbitalAmt) === parseFloat(searchInputVal) | 
                            parseFloat(invoiceListAll[i].PaidDebitAmt) === parseFloat(searchInputVal) | 
                            parseFloat(invoiceListAll[i].PaidCheckAmt) === parseFloat(searchInputVal) 
                        )
                        {
                            if(removeBgColor !== invoiceListAll[i].InvoiceNumber)
                            {
                                searchFound = true
                            }
                            searchCommanCode(i)
                            break;
                        }
                    }
                }

                if(!(searchFound))
                {
                    alert("The search reached end of rows.");
                    return
                }
            }
        }
    }

    
    const handleReset = () => {
        setInvoiceListAll([])
        setInvoiceList([])
        setInvoiceData([])
        setInvoiceTransData([])
        setUserFilterVal("All")
        setInvoiceTypeFilterVal("AINV")
        let currentDate = new Date();
        setInvoiceDate(currentDate);

        let user_info = localStorage.getItem("user_info")
        user_info = JSON.parse(user_info);
        let user_id_branch = user_info.UserId + " - " + user_info.BranchAccountName
        setSelectedBranch(user_id_branch);
        setUpdateBranch(user_id_branch);

        setSearchInputVal("");
        setSearchRadioVal("invoice");
    }

    const handlePrintList = async () =>{
        
        const printListRequestData = {
            "invoice_list": invoiceList,
            "invoice_date": invoiceDate.toISOString().split('T')[0],
            "cashier_user_id": filterUserID,
            "branch_name": updateBranch.split('- ')[1]
        }
        
        await HTTP.post('invoice-list-reports-pdf/print-list', printListRequestData, { responseType: 'blob'})
        .then(response => {
            console.log("-------print data-------")
            const pdfBlob = response.data;
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank')
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    const handlePrintSummaryReports = async (recon_daily_summary_rpt) =>{
        
        const printTotalSummaryRequestData = {
            "invoice_list": invoiceList,
            "invoice_date": invoiceDate.toISOString().split('T')[0],
            "cashier_user_id": filterUserID,
            "cashier_user_name": userFilterVal,
            "invoice_type": invoiceTypeFilterVal,
            "branch_name": updateBranch.split('- ')[1],
            "recon_daily_summary_rpt": recon_daily_summary_rpt
        }
        
        await HTTP.post('invoice-list-reports-pdf/print-summary-reports', printTotalSummaryRequestData, { responseType: 'blob'})
        .then(response => {
            console.log("-------print print-summary-reports data-------" + recon_daily_summary_rpt)
            const pdfBlob = response.data;
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank')
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

  return (
    <>
      <Header />
      <div className="my-page-content">
        <div className="container-fluid">

            <div className="row mt-0">
                <div className="col-lg-12 col-md-12 mb-0">
                    <div className="">
                        <div className="invoice-btns">

                            <div className="d-inline-block mx-1 p-0 mb-2">
                                <input type="radio" className='mx-1' name="exampleRadios" id="exampleRadios1" value="" checked={searchRadioVal === "invoice"}  onClick={()=> handleSearchRadioVal("invoice")}/>
                                <label htmlFor="add_invoice">{t("Invoice")} #</label><br/>

                                <input type="radio" className='mx-1' name="exampleRadios" id="exampleRadios1" value="" checked={searchRadioVal === "amt"} onClick={()=> handleSearchRadioVal("amt")}/>
                                <label htmlFor="add_invoice">{t("Dollar Amt")}</label>
                            </div>

                            <input type="text" placeholder={t("Enter Value")} value={searchInputVal} onChange={handleSearchInputVal} className="top-row-input"/>
                            <DatePicker oneTap style={{ width: 150, border:"0px solid rgb(175 139 231)", borderRadius:"5px", marginRight:"8px", marginBottom:"3px", padding:"5px"}} 
                                onChange={handleDateRange}
                                format = {dateFormat}
                                value={invoiceDate}
                            />

                            <button type="button" className="top-row-button btn-secondary" onClick={()=> handleSearch()}>{t("Search")}</button>
                            <button type="button" className="top-row-button btn-secondary" onClick={()=> handleReset()}>{t("Reset/Refresh")}</button>

                            <select name="type" id="type" className="top-row-input" onChange={handleChangeUsers}>
                            {
                                users?
                                    users.map(data => (
                                        <option value={data.UserName + "," + data.SortOrder + "," + data.UserId} selected={userFilterVal === data.UserName}>{data.EmplName}</option>                        
                                    ))                                
                                : ""
                            }                        
                            </select>
                            <select name="type" id="type" className="top-row-input" onChange={handleChangeInvoiceType}>

                            {
                                invoiceType?     
                                    invoiceType.map(data => (
                                        <option value={data.InvoiceTypeCode + "," + data.TypeCharOfInvoice} selected={invoiceTypeFilterVal === data.InvoiceTypeCode}>{data.InvoiceTypeName}</option>                        
                                    ))                      
                                : ""
                            }       

                            </select>
                            
                            {
                            displayBranchSelectionFlag ? 
                            <button type="button" onClick={handleShow} className="top-row-button btn-secondary">{t("Select a Different Branch")}</button> 
                            : ""
                            }

                            <div className="user-profile dropdown top-row-input d-inline-block">
                                <Link to="#" data-bs-toggle="dropdown"  className="text-decoration-none" aria-expanded="false">
                                    <div className="wrapper">
                                        <span>{t("Print")}<i className="vvg-arrow-down mx-2"></i> </span>
                                    </div>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-start">
                                    <Link to="#" className="dropdown-item" onClick={()=> handlePrintSummaryReports(false)}><i className=""></i>{t("Print Total Summary")}</Link>
                                    <Link to="#" className="dropdown-item" onClick={()=> handlePrintList()}><i className=""></i>{t("Print List")}</Link>
                                    <Link to="#" className="dropdown-item" onClick={()=> handlePrintSummaryReports(true)}><i className=""></i>{t("Recon Daily Summary Rpt")}</Link>
                                
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-0">
                <div className="col-lg-6">
                    <label htmlFor="add_invoice">{t("Invoice Payment")}</label><br/>
                </div>
                <div className="col-lg-6">
                </div>
            </div>

            <div className="row mt-0">
                <div className="col-lg-12 col-md-12 mb-0 ">
                    <div className="invoice-table primary-table table-responsive-sm " style={{width:"100%", overflowX:"auto", height:"300px", overflowY:"auto"}}>
                        <table className="table table-borderless" ref={tableRef}>
                            <thead>
                                <tr className="table-th">
                                    <th className="text-center">{t("Invoice")} #</th>
                                    <th className="text-center">{t("Invoice Time")}</th>
                                    <th className="price-align">{t("Total Due")}</th>
                                    <th className="price-align">{t("Paid Cash")}</th>
                                    <th className="price-align">{t("Paid Credit Card")}</th>
                                    <th className="price-align">{t("Paid Orbital")}</th>
                                    <th className="price-align">{t("Paid Debit")}</th>
                                    <th className="price-align">{t("Paid Check")}</th>
                                    <th className="price-align">{t("Paid ACH")}</th>
                                    <th className="price-align">{t("OVER")}</th>
                                    <th className="price-align">{t("SHORT")}</th>
                                    <th className="text-center">{t("Entered By")}</th>
                                    <th className="text-center">{t("Notes")}</th>
                                    <th className="text-center">{t("Entered Date")}</th>
                                    <th className="text-center">{t("Modified By")}</th>
                                    <th className="text-center">{t("Modified Date")}</th>
                                    <th className="text-center">{t("PPC")}</th>
                                </tr>
                            </thead>

                            <tbody>
                            {
                                invoiceList?
                                invoiceList.map((data, index) => (
                                    <tr className='removeColor mb-1' style={{backgroundColor: data.PaymentEnteredByUserId ? "" : "#ffff00"}} id={data.InvoiceNumber} onClick={()=>handleremovediv(index, data.InvoiceNumber, true, data.PaymentEnteredByUserId)}>
                                        <td>{data.InvoiceNumber}</td>
                                        <td>{ moment(new Date(data.InvoiceDatetime)).format(dateFormat.toUpperCase() + ' hh:mm A')}</td>
                                        <td className="price-align">{data.TotalPriceAmt ?'$' + parseFloat(data.TotalPriceAmt).toFixed(2) : ""}</td>
                                        <td className="price-align">{data.PaidCashAmt ? '$' + parseFloat(data.PaidCashAmt).toFixed(2) : ""}</td>
                                        <td className="price-align">{data.PaidCreditCard ? '$' + parseFloat(data.PaidCreditCard).toFixed(2) : ""}</td>
                                        <td className="price-align">{data.PaidOrbitalAmt ? '$' + parseFloat(data.PaidOrbitalAmt).toFixed(2) : ""}</td>
                                        <td className="price-align">{data.PaidDebitAmt ? '$' + parseFloat(data.PaidDebitAmt).toFixed(2) : ""}</td>
                                        <td className="price-align">{data.PaidCheckAmt && !data.ACHCheckFlag ? '$' + parseFloat(data.PaidCheckAmt).toFixed(2) : ""}</td>
                                        <td className="price-align">{data.PaidCheckAmt && data.ACHCheckFlag ? '$' + parseFloat(data.PaidACH).toFixed(2) : ""}</td>
                                        <td className="price-align">{data.OverAmt ? '$' + parseFloat(data.OverAmt).toFixed(2) : ""}</td>
                                        <td className="price-align">{data.ShortAmt ? '$' + parseFloat(data.ShortAmt).toFixed(2) : ""}</td>
                                        <td>{data.PaymentEnteredByUserId}</td> 
                                        <td>{data.Notes}</td>
                                        <td>{ data.PaymentEnteredDate ? moment(new Date(data.PaymentEnteredDate)).format(dateFormat.toUpperCase() + ' hh:mm A') : ''}</td>
                                        <td>{ data.PaymentModifiedByUserId}</td>
                                        <td>{ data.PaymentModifiedDate ? moment(new Date(data.PaymentModifiedDate)).format(dateFormat.toUpperCase() + ' hh:mm A') : ''}</td>
                                        <td>{data.SalesPersonId}</td>
                                    </tr>
                                ))                                
                                : ""
                            }  
                            {
                                invoiceList[0]?
                                <tr className="total-row">
                                    <td colSpan={2}>Total :</td>
                                    
                                    <td className="price-align">{totalDue ? '$' + parseFloat(totalDue).toFixed(2) : "$0.00"}</td>
                                    <td className="price-align">{totalPaidCashAmt ? '$' + parseFloat(totalPaidCashAmt).toFixed(2) : "$0.00"}</td>
                                    <td className="price-align">{totalPaidCreditCard ? '$' + parseFloat(totalPaidCreditCard).toFixed(2) : "$0.00"}</td>
                                    <td className="price-align">{totalPaidOrbitalAmt ? '$' + parseFloat(totalPaidOrbitalAmt).toFixed(2) : "$0.00"}</td>
                                    <td className="price-align">{totalPaidDebitAmt ? '$' + parseFloat(totalPaidDebitAmt).toFixed(2) : "$0.00"}</td>
                                    <td className="price-align">{totalPaidCheckAmt ? '$' + parseFloat(totalPaidCheckAmt).toFixed(2) : "$0.00"}</td>
                                    <td className="price-align">{totalPaidACH ? '$' + parseFloat(totalPaidACH).toFixed(2) : "$0.00"}</td>
                                    <td className="price-align">{totalOverAmt ? '$' + parseFloat(totalOverAmt).toFixed(2) : "$0.00"}</td>
                                    <td className="price-align">{totalShortAmt ? '$' + parseFloat(totalShortAmt).toFixed(2) : "$0.00"}</td>

                                    <td colSpan={6}></td>
                                    
                                </tr>
                                : ""
                            }
                                
                            </tbody>
                        </table>
                        <div>

                         </div>
                    
                    </div>
                </div>
            </div>

            <div className="row mt-1 mx-1 text-center">
                <div className="col-12 ">
                    
                    {invoiceList.length !=0 ? 
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <PageSize pageSize={pageSize} totalRows={invoiceList[0]['TotalRows']} setPageSize={setPageSize}></PageSize>

                            <CustomPagination 
                            getInvoice={handleGetInvoiceList} 
                            fromDateIn={fromDateInvoice} 
                            totalRows={invoiceList[0]['TotalRows']} 
                            calledFrom={"invoiceList"}
                            pageSize={pageSize}>
                            
                            </CustomPagination>
                        </div>
                    :""}

                </div>
            </div>

            <div className="row mt-3">

                <div className="col-lg-7 col-md-12 mb-0">
                    <div className="payment-box">

                        <div className="row mt-0">

                            <div className="col-lg-4 col-md-5 mb-0">
                                <div className="other-payment-mode">
                                    <div className="payment-form ">

                                        <div className="form-group ">
                                            <label htmlFor="dfs" className="form-label"><b>{t("Payment")}</b></label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="dfdf" className="form-label  custom-label">{t("Cash")}:</label>
                                            <input type="text" className="custom-input price-align" name="invoice_id" value={invoiceData.PaidCashAmt ? '$' + parseFloat(invoiceData.PaidCashAmt).toFixed(2) : ""} size={12} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="dfdf" className="form-label custom-label">{t("Check")}:</label>
                                            <input type="text" className="custom-input price-align" name="invoice_id" value={invoiceData.PaidCheckAmt ? '$' + parseFloat(invoiceData.PaidCheckAmt).toFixed(2) : ""} size={12} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="dfdf" className="form-label custom-label">{t("Visa")}:</label>
                                            <input type="text" className="custom-input price-align" name="invoice_id" value={invoiceData.PaidVisaAmt ? '$' + parseFloat(invoiceData.PaidVisaAmt).toFixed(2) : ""} size={12} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="dfdf" className="form-label custom-label">{t("Master")}:</label>
                                            <input type="text" className="custom-input price-align" name="invoice_id" value={invoiceData.PaidMasterAmt ? '$' + parseFloat(invoiceData.PaidMasterAmt).toFixed(2) : ""} size={12} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="dfdf" className="form-label custom-label">{t("Amex")}:</label>
                                            <input type="text" className="custom-input price-align" name="invoice_id" value={invoiceData.PaidAmexAmt ? '$' + parseFloat(invoiceData.PaidAmexAmt).toFixed(2) : ""} size={12} disabled />
                                        </div>
                                        <div className="form-group ">
                                            <label htmlFor="dfdf" className="form-label custom-label">{t("Discover")}: </label>
                                            <input type="text" className="custom-input price-align" name="invoice_id" value={invoiceData.PaidDiscoverAmt ? '$' + parseFloat(invoiceData.PaidDiscoverAmt).toFixed(2) : ""} size={12} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="dfdf" className="form-label custom-label">{t("Debit")}:</label>
                                            <input type="text" className="custom-input price-align" name="invoice_id" value={invoiceData.PaidDebitAmt ? '$' + parseFloat(invoiceData.PaidDebitAmt).toFixed(2) : ""} size={12} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="dfdf" className="form-label custom-label">{t("Orbital")}:</label>
                                            <input type="text" className="custom-input price-align" name="invoice_id" value={invoiceData.PaidOrbitalAmt ? '$' + parseFloat(invoiceData.PaidOrbitalAmt).toFixed(2) : ""} size={12} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="dfdf" className="form-label custom-label">{t("Com Data")}:</label>
                                            <input type="text" className="custom-input price-align" name="invoice_id" value={invoiceData.PaidComDataAmt ? '$' + parseFloat(invoiceData.PaidComDataAmt).toFixed(2) : ""} size={12} disabled />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-7 mb-0">

                                <div className="row">

                                    <div className="col-6 ">
                                        <div className="other-payment-mode">
                                            <div className="payment-form ">
                                                <div className="form-group">
                                                    <label htmlFor="dfdf" className="form-label custom-label">{t("Invoice")} #:</label>
                                                    <input type="text" className="custom-input" name="invoice_id" value={invoiceData.InvoiceNumber ? invoiceData.InvoiceNumber : ""} size={12} disabled />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="dfdf" className="form-label custom-label">{t("Invoice Amt")}:</label>
                                                    <input type="text" className="custom-input price-align" name="invoice_id" value={invoiceData.TotalPriceAmt ? '$' + parseFloat(invoiceData.TotalPriceAmt).toFixed(2) : ""} size={12} disabled />
                                                </div>
                                                <div className="form-group ">
                                                    <input type="checkbox" className="custom-input" name="invoice_id" checked={invoiceData.ACHCheckFlag} disabled />
                                                    <label htmlFor="dfdf" className="form-label " style={{width:'90%', textAlign:"left"}}>{t("ACH Check")}</label>
                                                </div>
                                                <div className="form-group ">
                                                    <label htmlFor="dfdf" className="form-label custom-label">{t("Payment")}: </label>
                                                    <input type="text" className="custom-input" name="invoice_id" value={invoiceData.PaymentEnteredDate ? moment(new Date(invoiceData.PaymentEnteredDate)).format(dateFormat.toUpperCase() + ' hh:mm A') : ""} size={12} disabled />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="dfdf" className="form-label custom-label">{t("Over")}:</label>
                                                    <input type="text" className="custom-input price-align" name="invoice_id" value={invoiceData.OverAmt ? parseFloat(invoiceData.OverAmt).toFixed(2) : ""} size={12} disabled />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="dfdf" className="form-label custom-label">{t("Short")}:</label>
                                                    <input type="text" className="custom-input price-align" name="invoice_id" value={invoiceData.ShortAmt ? '$' + parseFloat(invoiceData.ShortAmt).toFixed(2) : ""} size={12} disabled />
                                                </div>

                                                <div className="form-group ">
                                                    <input type="checkbox" className="custom-input"  name="invoice_id" size={6} checked={invoiceData.CODFlag} disabled />
                                                    <label htmlFor="dfdf" className="form-label" style={{width:'90%', textAlign:"left"}}>{t("OUT FOR DELIVERY")}</label>
                                                </div>
                                               

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="other-payment-mode">
                                            <div className="payment-form ">
                                                <div className="form-group">
                                                    
                                                    <label htmlFor="dfdf" className="form-label custom-label">{t("Balance Due")}:</label>
                                                    <input type="text" className="custom-input price-align" name="invoice_id"
                                                        value={
                                                            '$' + parseFloat(
                                                                (
                                                                    (invoiceData.TotalPriceAmt ? invoiceData.TotalPriceAmt : 0.00)
                                                                ) -
                                                                (
                                                                    (invoiceData.PaidCashAmt ? parseFloat(invoiceData.PaidCashAmt) : 0.00) +
                                                                    (invoiceData.PaidOrbitalAmt ? parseFloat(invoiceData.PaidOrbitalAmt) : 0.00) +
                                                                    (invoiceData.PaidDebitAmt ? parseFloat(invoiceData.PaidDebitAmt) : 0.00) +
                                                                    (invoiceData.PaidCheckAmt ? parseFloat(invoiceData.PaidCheckAmt) : 0.00) +
                                                                    (invoiceData.PaidVisaAmt ? parseFloat(invoiceData.PaidVisaAmt) : 0.00) +
                                                                    (invoiceData.PaidMasterAmt ? parseFloat(invoiceData.PaidMasterAmt) : 0.00) +
                                                                    (invoiceData.PaidAmexAmt ? parseFloat(invoiceData.PaidAmexAmt) : 0.00) +
                                                                    (invoiceData.PaidComDataAmt ? parseFloat(invoiceData.PaidComDataAmt) : 0.00) +
                                                                    (invoiceData.PaidDiscoverAmt ? parseFloat(invoiceData.PaidDiscoverAmt) : 0.00)
                                                                ) -
                                                                (invoiceData.ToDateDepositAmt ? parseFloat(invoiceData.ToDateDepositAmt) : 0.00) +
                                                                (invoiceData.RefInvoiceNumberTotalPriceAmt ? parseFloat(invoiceData.RefInvoiceNumberTotalPriceAmt) : 0.00) +
                                                                (invoiceData.RefInvoiceNumber2TotalPriceAmt ? parseFloat(invoiceData.RefInvoiceNumber2TotalPriceAmt) : 0.00) +
                                                                (invoiceData.RefInvoiceNumber3TotalPriceAmt ? parseFloat(invoiceData.RefInvoiceNumber3TotalPriceAmt) : 0.00)
                                                            
                                                            ).toFixed(2)
                                                        }
                                                    size={12} disabled />

                                                </div>
                                                <div className="form-group ">
                                                    <label htmlFor="dfdf" className="form-label custom-label">{t("Customer")} #: </label>
                                                    <input type="text" className="custom-input" name="invoice_id" value={invoiceData.CustomerId ? invoiceData.CustomerId : ""} size={12} disabled />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="dfdf" className="form-label custom-label">{t("Cust. Name")}:</label>
                                                    <label htmlFor="dfdf" className="form-label" style={{width:'auto', justifyContent:"center", textAlign:"center"}}>
                                                        {invoiceData.CustomerBillToName ? invoiceData.CustomerBillToName : ""}
                                                    </label>
                                                    {/* <input type="text" className="custom-input" name="invoice_id" value={invoiceData.CustomerBillToName ? invoiceData.CustomerBillToName : ""} size={12}/> */}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="dfdf" className="form-label custom-label">{t("Salesperson")}:</label>
                                                    <label htmlFor="dfdf" className="form-label " style={{width:'130px', justifyContent:"center", wordWrap:"break-word", textAlign:"center"}}>
                                                        {invoiceData.SalesPersonId ? invoiceData.SalesPersonId + "-" + invoiceData.SalesPersonFirstName + " " + invoiceData.SalesPersonLastName : ""}</label>

                                                    {/* <input type="text" className="custom-input" name="invoice_id" value={invoiceData.SalesPersonId + "-" + invoiceData.SalesPersonFirstName + " " + invoiceData.SalesPersonLastName} size={12}/> */}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="dfdf" className="form-label custom-label">{t("Ref")}:</label>
                                                    <input type="text" className="custom-input" name="invoice_id" value={invoiceData.RefInvoiceNumber ? invoiceData.RefInvoiceNumber : ""} size={12} disabled />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row mt-3">
                                    <div className="col">
                                        <label htmlFor="sdfs" className="label-amount">{t("Payment Notes")}: </label>
                                        <input type="text" className="form-control custom-input-area" value={invoiceData.Notes ? invoiceData.Notes : ""} disabled />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-lg-5 col-md-12 mb-0">
                    <div className="payment-box">
                            <div className="row mt-0">
                                <div className="col-6">
                                    </div>
                                <div className="col-6">
                                    <div className="invoice-btns">
                                        <label htmlFor="add_invoice" className="form-label">{t("Total Records")} </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-0">
                                <div className="col-lg-12 col-md-12 mb-0">

                                <div className="invoice-table primary-table table-responsive-sm" style={{width:"100%", overflowX:"auto", height:"300px", overflowY:"auto"}}>
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th className="text-center">{t("Invoice")} #</th>
                                            <th className="text-center">{t("JE ID")}</th>
                                            <th className="text-center">{t("Payment Type")}</th>
                                            <th className="text-center">{t("Media")}</th>
                                            <th className="text-center">{t("Total Trans Amt")}</th>
                                            <th className="text-center">{t("Amt Applied")}</th>
                                            <th className="text-center">{t("Seq")}. #</th>
                                            <th className="text-center">{t("Payment Entered By")}</th>
                                            <th className="text-center">{t("Payment Date")}</th>
                                            <th className="text-center">{t("Payment Branch")}</th>
                                            <th className="text-center">{t("Invoice Branch")}</th>
                                            <th className="text-center">{t("Invoice Date")}</th>
                                            <th className="text-center">{t("Payment Invoices")}</th>
                                            <th className="text-center">{t("Payment Invoice List")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                {
                                    invoiceTransData ?

                                    invoiceTransData.map((transData, index) => (
                                        
                                        <tr id={index} >
                                            <td className="text-center">{transData.InvoiceNumber}</td>
                                            <td className="text-end">{transData.JournalEntryID}</td>
                                            <td className="text-end">{transData.PaymentType}</td>
                                            <td className="text-end">{transData.PaymentMedia}</td>
                                            <td className="text-end">{transData.TotalTransAmt ? '$' + parseFloat(transData.TotalTransAmt).toFixed(2) : ""}</td>
                                            <td className="text-end">{transData.AmtAppliedToThisInvoice ? '$' + parseFloat(transData.AmtAppliedToThisInvoice).toFixed(2) : ""}</td>
                                            <td className="text-end">{transData.PaymentSeqNumber}</td>
                                            <td className="text-end">{transData.PaymentEnteredByUserId}</td>
                                            <td className="text-end">{transData.PaymentEnteredDate ? moment(new Date(transData.PaymentEnteredDate)).format(dateFormat.toUpperCase() + ' hh:mm A') : ''}</td>
                                            <td className="text-end">{transData.UserBranchAccountName}</td>
                                            <td className="text-end">{transData.InvoiceBranchName}</td>

                                            <td className="text-end">{transData.InvoiceDate ? moment(new Date(transData.InvoiceDate)).format(dateFormat.toUpperCase() + ' hh:mm A') : ''}</td>
                                            <td className="text-end">{transData.NumOfInvoiceThisTransApplied}</td>
                                            <td className="text-end">{transData.ListOfInvoiceThisTransApplied}</td>
                                        </tr>

                                    ))
                                    : ""
                                }
                                    
                                    </tbody>
                                </table>
                            </div>

                                </div>
                            </div>
                    </div>
                </div>
            </div>

        </div>
      </div>
      <Footer />

    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
    >
        <Modal.Header>
            <Modal.Title>Branch Selection</Modal.Title>
        </Modal.Header>

        <Modal.Body>

            <div style={{height:"250px", overflowY:"auto"}}>
                <table className="table">
                    <thead>
                        <tr className="">
                            <th scope="col">Selected</th>
                            <th scope="col-11 ">Branch</th>
                        </tr>
                    </thead>
                    <tbody >

                    {
                        branchData?
                                
                        branchData.map(data => (

                            <tr  onChange={() => handleChangeBranch(data.BranchComb)}>
                                <td>
                                    <div className="form-check">
                                        <input type="checkbox" className="BranchComb-CheckBox" value={data.BranchComb} checked={data.BranchName == selectedBranch.split(' - ').slice(1).join(' - ')}/>
                                    </div>
                                </td>
                                <td>{data.BranchComb}</td>
                            </tr>
                        
                        ))  
                                                            
                        : ""
                    } 

                    </tbody>
                </table>
            </div>

            

        </Modal.Body>

            <div className="align-items-center text-center ">
                <Button variant="primary mx-2 mb-3" onClick={()=> handleSetBranch()} >OK</Button>
                <Button variant="primary mx-2 mb-3" onClick={handleClose}>Cancel</Button>
            </div>

    </Modal>

      <Outlet />
    </>
  );
}
